<template>
  <div>
    <Header></Header>
    <headmenu></headmenu>
    <div class="service">
      <div class="title">
        <h1>系统消息</h1>
        <div class="line"></div>
      </div>
      <div class="list">
        <div v-if="list.data.length > 0">
          <div
            class="list_item"
            v-for="(item, index) in list.data"
            :key="index"
          >
            <div class="right">
              <h3>{{ item.message_title }}</h3>
              <span>{{ item.message_content }}</span>
              <h5 style="font-weight: normal;font-size: 16px;">发布时间：{{ item.create_time_text }}</h5>
              <el-button
                type="primary"
                @click="goto(`/sysdetail/${item.message_id}`)"
                >查看详情+</el-button
              >
            </div>
          </div>
        </div>
        <div v-else style="height: 50px; line-height: 50px; text-align: center">
          没有更多了
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="list.current_page"
          @current-change="currentchange"
          :total="list.total"
        >
        </el-pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { getnoticeList } from "../api/index";
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import headmenu from "../components/headmenu.vue";
export default {
  data() {
    return {
      list: {},
      //   listarr: [],
      page: 1,
      limit: 10,
      //   ismore: true,
    };
  },
  components: {
    Header,
    Footer,
    headmenu,
  },
  created() {
    this.getnoticeList();
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
    getnoticeList() {
      this.Loading(".list_item");
      getnoticeList({
        page: this.page,
      }).then((res) => {
        this.isloading.close();
        // console.log("会员服务列表", res);
        if (res.data.code == 1) {
          // console.log("111111111111", res.data.data);
          this.list = res.data.data;
          //   this.listarr = [...this.listarr, ...res.data.data.data];
        } else {
        }
      });
    },
    currentchange(page) {
      // console.log(page)
      this.page = page;
      this.limit = 10;
      this.getnoticeList();
    },
    // load(e) {
    //   // console.log("11111111", e);
    //   if (this.list.per_page * this.list.current_page < this.list.total) {
    //     // this.ismore = true;
    //     this.page++;
    //     this.getnoticeList();
    //   } else {
    //     // this.ismore = false;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.service {
  width: 100%;
  margin: 0 auto 60px;

  .title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 32px;
    }

    .line {
      margin-top: -5px;
      width: 50px;
      text-align: center;
      border-bottom: 4px solid #1890ff;
    }
  }
  /deep/.el-pagination.is-background .el-pager li {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }

  /deep/.el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }

  /deep/.el-pagination.is-background .btn-prev:disabled {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }

  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    border: 1px solid #1890ff;
    background-color: #fff;
    color: #1890ff;
    height: 30px;
  }

  /deep/.el-pagination.is-background .btn-prev {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }
  .list {
    width: 70%;
    margin: 45px auto 80px;
    //height: 750px;
    .el-pagination {
      text-align: center;
    }

    .list_item {
      border-radius: 10px;
      margin-bottom: 20px;
      background-color: #fff;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 233px;
      position: relative;

      .right {
        display: flex;
        flex-direction: column;
        margin-top: -60px;
        width: 75%;
        margin-left: -50px;

        h3 {
          font-size: 20px;
          margin-bottom: 10px;
        }

        span {
          font-size: 18px;
          line-height: 30px;
        }

        .el-button--primary {
          position: absolute;
          width: 192px;
          right: 35px;
          top: 70%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .service .title h1 {
    font-size: 24px;
  }

  .service .text span {
    font-size: 16px;
    line-height: 30px;
  }

  .service .text {
    padding: 20px 20px;
    font-size: 16px;
  }

  .service {
    width: 1000px;
    margin: 20px auto 30px;
  }

  .service .list {
    width: 1000px;
    margin: 20px auto 80px;
    height: auto;
  }

  .service .list .list_item {
    border-radius: 10px;
    margin-bottom: 20px;
    height: 180px;
    justify-content: space-between;
    padding: 15px;
  }

  .service .list .list_item .right h3 {
    font-size: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .service .list .list_item .right h5 {
    font-size: 16px;
  }

  .service .list .list_item .right {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    width: 100%;
    margin-left: 0px;
  }

  .service .list .list_item .right .el-button--primary {
    position: absolute;
    width: 160px;
    right: 15px;
    bottom: 15px;
    top: 75%;
  }
}

@media only screen and (max-width: 1100px) {
  .service {
    width: 750px;
    margin: 20px auto 30px;
  }

  .service .list {
    width: 750px;
    margin: 20px auto 80px;
    height: auto;
  }

  .service .list .list_item {
    border-radius: 10px;
    margin-bottom: 10px;
    height: 140px;
    max-height: 500px;
    justify-content: space-between;
    padding: 10px;
    flex-wrap: wrap;
  }

  .service .list .list_item .right h3 {
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .service .list .list_item .right h5 {
    font-size: 14px;
  }

  .service .list .list_item .right span {
    font-size: 14px;
    line-height: 25px;
  }

  .service .list .list_item .right .el-button--primary {
    position: absolute;
    width: 160px;
    right: 15px;
    bottom: 15px;
    top: 67%;
  }
}

@media only screen and (max-width: 768px) {
  .service .title h1 {
    font-size: 18px;
  }

  .service {
    width: 98%;
    margin: 70px auto 30px;
  }

  .service .list {
    width: 98%;
    margin: 20px auto 80px;
    height: auto;
  }

  .service .list .list_item .left {
    width: 100%;
  }

  .service .list .list_item .right {
    width: 100%;
  }

  .service .list .list_item .right .el-button--primary {
    position: static;
    width: 160px;
    margin-top: 5px;
  }

  .service .list .list_item .right h3 {
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .service .list .list_item .right h5 {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
}
</style>