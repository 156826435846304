<!-- 登录 -->
<template>
	<div>
		<Header></Header>
		<headmenu></headmenu>
		<div class="bei">
			<!-- 登录 -->
			<div class="register">
				<div class="login_text">
					<div class="login11">
						<h4>注册</h4>
						<div class="line"></div>
					</div>
				</div>
				<div class="input_com">
					<el-input class="item" v-model="form.username" placeholder="用户名">
					</el-input>
					<el-input class="item" v-model="form.company" placeholder="单位名称">
					</el-input>
					<el-cascader class="item" :options="options" v-model="province" @change="handleChange"
						placeholder="地址">
					</el-cascader>
					<el-input class="item" v-model="form.business" placeholder="主营业务">
					</el-input>
					<el-input class="item" v-model="form.nickname" placeholder="真实姓名">
					</el-input>
					<el-input class="item" v-model="form.email" placeholder="邮箱">
					</el-input>
					<el-input class="item" v-model="form.password" type="password" placeholder="6-16位密码，区分大小写">
					</el-input>
					<el-input class="item" v-model="form.mobile" placeholder="请输入手机号">
						<template slot="prepend">
							<el-select v-model="qvhao" placeholder="86+" style="width: 85px; outline: none"
								size="large">
								<el-option label="87+" :value="1" />
								<el-option label="89+" :value="2" />
								<el-option label="85+" :value="3" />
							</el-select>
						</template>
					</el-input>
					<el-input v-model="form.code" placeholder="请输入验证码">
						<template slot="suffix">
							<div style="
                  width: 200px;
                  height: 48px;
                  text-align: center;
                  color: #1890ff;
                  line-height: 48px;
                  font-size: calc(100vw * 20 / 1920);
                  background: #fff;
                  border-left: 1px solid #ccc;
                  border-top: 1px solid #ccc;
                " @click="sendcode">
								{{ getCodeText }}
							</div>
						</template>
					</el-input>
				</div>
				<button class="login_but" @click="toregister">注册</button>
				<div style="
            color: #1890ff;
            font-size: 16px;
            margin-left: 11.35%;
            cursor: pointer;
          " @click="goto('/login')">
					使用已有帐号登录
				</div>
				<div style="
            display: flex;
            align-items: center;
            margin-left: 11.35%;
            margin-top: 2%;
            margin-bottom: 4%;
          ">
					<div style="
              margin-right: 1%;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
            ">
						<input type="checkbox" style="margin: auto" />
					</div>

					<div style="display: flex; justify-content: space-between; width: 85%">
						<div class="yuedu" style="font-size: calc(100vw * 18 / 1920)">
							我已阅读并接受《中国电子元件行业协会平台会员注册协议》
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>
<script>
	import Header from "../components/header.vue";
	import Footer from "../components/footer.vue";
	import headmenu from "../components/headmenu.vue";
	import {
		pcaTextArr
	} from "element-china-area-data";
	import {
		sendcode,
		register
	} from "../api";
	export default {
		data() {
			return {
				form: {
					username: "",
					company: "",
					business: "",
					nickname: "",
					email: "",
					password: "",
					mobile: "",
					province: "",
					city: "",
					area: "",
					code: "",
					event: "register",
				},
				qvhao: "",
				options: pcaTextArr,
				selectedOptions: [],
				getCodeText: "点击发送验证码",
				codeFlag: true,
				countdown: 60,
				getCodeisWaiting: false,
			};
		},
		components: {
			Header,
			Footer,
			headmenu
		},
		methods: {
			toregister() {
				this.Loading(".bei");
				register({
					...this.form
				}).then((res) => {
					if (res.data.code == 1) {
						this.$message.success(res.data.msg);
						localStorage.setItem("token", res.data.data.userinfo.token);
						localStorage.setItem("username", res.data.data.userinfo.username);
						localStorage.setItem("avatar", res.data.data.userinfo.avatar);
						setTimeout(() => {
							this.goto("/index");
						}, 500);
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
			goto(url) {
				this.$router.push(url);
			},
			handleChange(value) {
				// console.log('value',value);
				// this.selectedOptions = [[value[0]], [value[1]], [value[2]]];
				// this.form.province = this.selectedOptions.join("/");
				this.form.province = value[0];
				this.form.city = value[1];
				this.form.area = value[2];
				// console.log('province',this.province);
			},
			sendcode() {
				let that = this;
				if (that.codeFlag) {
					that.getCodeText = "发送中..."; //XX秒后重新发送
					that.codeFlag = false;
					sendcode({
						mobile: that.form.mobile,
						event: "register",
					}).then((res) => {
						if (res.data.code == 1) {
							that.$message.success(res.data.msg);
							that.getCodeText = that.countdown + "秒后重新发送";
							that.getCodeisWaiting = true;
							setTimeout(() => {
								that.setTimer(); //调用定时器方法
							}, 1000);
						} else {
							that.$message.error(res.data.msg);
							this.codeFlag = true;
							this.getCodeText = "点击获取验证码";
						}
					});
				}
			},
			setTimer() {
				let holdTime = 60; //定义变量并赋值
				this.getCodeText = "重新获取(60)";
				let Timer = setInterval(() => {
					if (holdTime <= 0) {
						this.getCodeisWaiting = false;
						this.getCodeText = "点击获取验证码";
						this.codeFlag = true;
						this.clearInterval(Timer); //清除该函数
						return; //返回前面
					}
					this.getCodeText = "重新获取(" + holdTime + ")";
					holdTime--;
				}, 1000);
			},
		},
	};
</script>

<style lang='scss'>
	input:focus {
		box-shadow: 0px 0px 10px 0px rgba(127, 127, 127, 0.1);
		border-color: #ffad01;
	}

	.login_img_deng {
		width: 166px;
		height: 55px;
		background: #ffffff;
		border-radius: 12px 12px 12px 12px;
		opacity: 1;
		// text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		// line-height: 55px;
	}

	.bei {
		// height: 960px;
		height: 100%;
		// background: url(../image/imagess/Group2.png) no-repeat 100% 100%;
		background-size: contain;
		overflow: hidden;

		/* 登录 */
		.register {
			width: 37.23%;
			// height: 17.81%;
			background: #ffffff;
			// box-shadow: 0px 0px 4px 0px rgba(108, 40, 253, 0.24);
			border-radius: 20px;
			opacity: 1;
			border: 1px solid #ffffff;
			margin: 2% 32.5% 3% 30.26%;

			.input_com {
				width: 80%;
				margin: 0 auto;

				.item {
					margin-bottom: 10px;
				}

				.item {
					margin-bottom: 10px;
					width: 100%;
				}

				.el-input__inner {
					width: 100%;
					height: 50px;
					outline: none;
					font-size: 20px;
				}

				.el-input-group__prepend .el-input {
					height: 50px;
				}
			}

			.login_text {
				height: 36px;
				font-size: 20px;
				line-height: 35px;
				text-align: center;
				margin: 20px auto 30px;
				display: flex;
				justify-content: center;
				width: 70%;

				.login11 {
					width: 40%;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;

					h4 {
						color: #1890ff;
						font-size: 24px;
						font-weight: 400;
					}

					.noline {
						margin-top: -23px;
						margin-bottom: 15px;
					}

					.line {
						margin-top: -23px;
						margin-bottom: 15px;
						width: 20%;
						text-align: center;
						border-bottom: 3px solid #1890ff;
					}
				}
			}

			.login_but {
				width: 77.34%;
				height: 64px;
				background: #1890ff;
				border-radius: 5px 5px 5px 5px;
				opacity: 1;
				font-size: calc(100vw * 20 / 1920);
				font-weight: 400;
				color: #ffffff;
				line-height: 23px;
				margin: 2% 11.1% 3.07%;
				border: 0;
			}
		}
	}

	@media only screen and (max-width: 1366px) {
		.bei .register {
			width: 500px;
			background: #ffffff;
			border-radius: 20px;
			opacity: 1;
			border: 1px solid #ffffff;
			margin: 0 auto;
			margin-top: 2%;
			padding-bottom: 80px;
		}
		.bei .register .login_but {
		    width: 80%;
		    height: 40px;
		    background: #1890ff;
		    border-radius: 5px 5px 5px 5px;
		    opacity: 1;
		    font-size: 14px;
		    font-weight: 400;
		    color: #ffffff;
		    line-height: 23px;
		    margin: 2% 10%;
		    border: 0;
		}
		.yuedu{
			font-size: 14px !important;
		}
		.el-input__suffix-inner div{
			font-size: 14px !important;
		}
	}

	@media only screen and (max-width: 768px) {
		.bei{
			margin-top: 70px;
		}
		.bei .register {
		    width: 350px;
		    background: #ffffff;
		    border-radius: 10px;
		    opacity: 1;
		    border: 1px solid #ffffff;
		    margin: 0 auto;
		    margin-top: 2%;
		    padding-bottom: 80px;
		}
		.bei .register .login_text .login11 h4 {
			color: #1890ff;
			font-size: 18px;
			font-weight: 400;
		}

		.bei .register .login_text {
			height: 36px;
			font-size: 18px;
			line-height: 35px;
			text-align: center;
			margin: 10px auto 15px;
			display: flex;
			justify-content: center;
			width: 80%;
		}

		.bei .register .input_com .el-input__inner {
			width: 100%;
			height: 40px;
			outline: none;
			font-size: 14px;
		}

		.bei .register .input_com .el-input-group__prepend .el-input {
			height: 40px;
		}
		.yuedu{
			font-size: 12px !important;
		}
		.el-input__suffix-inner div{
			font-size: 12px !important;
		}
	}
</style>