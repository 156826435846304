<template>
	<div class="box">
		<div class="sidebar">
			<sidebar></sidebar>
		</div>
		<div class="password">
			<Header></Header>
			<div class="password_cont">
				<div class="name"><span>*</span>修改密码</div>
				<!-- <el-input v-if="issetPwd===0" placeholder="请输入旧密码" class="input" v-model="old_password" clearable>
				</el-input> -->
				<el-input placeholder="请输入新密码" class="input" v-model="password" clearable>
				</el-input>
				<div class="but">
					<div class="submit" @click="tosave">保存</div>
					<div class="cancel" @click="clear">取消</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
	import {
		setPwd
	} from "../api/index";
	import Header from "../components/header2.vue";
	import Footer from "../components/footer.vue";
	import sidebar from "../components/sidebar.vue";
	export default {
		components: {
			Header,
			Footer,
			sidebar,
		},
		data() {
			return {
				old_password: "",
				password: "",
				// issetPwd:0
			};
		},
		mounted(){
			// this.issetPwd= this.$route.query.setPwd?1:0
		},
		methods: {
			tosave() {
				let that = this;
				that.Loading(".box");
				setPwd({
					// old_password: that.issetPwd===1?"":that.old_password,
					password: that.password,
					// setPwd:that.issetPwd
				}).then((res) => {
					that.isloading.close();
					if (res.data.code == 1) {
						that.$message.success(res.data.msg);
						window.localStorage.clear();
						setTimeout(() => {
							that.goto("/login");
						}, 500);
					} else {
						that.$message.error(res.data.msg);
					}
				});
			},
			clear() {
				(this.old_password = ""), (this.password = "");
			},
			goto(url) {
				this.$router.push(url);
			},
		},
	};
</script>

<style scoped lang="scss">
	body {
		padding: 0;
		margin: 0;
	}

	.box {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.sidebar {
		width: 18%;
	}

	.password {
		width: 82%;
		background: linear-gradient(180deg,
				rgba(171, 198, 246, 0.27) 0%,
				rgba(216, 224, 232, 0.1) 100%);
	}

	.password_cont {
		border-radius: 5px;
		background: #fff;
		margin: 34px 30px 63px 81px;
		padding: 50px 101px 50px 101px;
		position: relative;
	}

	.password_cont .name {
		color: #000;
		font-size: 18px;
	}

	.password_cont .name span {
		color: #de0000;
	}

	.password_cont .input {
		margin-top: 18px;
	}

	.password_cont .but {
		display: flex;
		justify-content: center;
		margin-top: 34px;
	}

	.password_cont .but div {
		margin: 0 10px;
		width: 212px;
		height: 50px;
		line-height: 50px;
		flex-shrink: 0;
		text-align: center;
		cursor: pointer;
	}

	.password_cont .but .submit {
		border-radius: 5px;
		background: #1890ff;
		color: #fff;
		font-size: 18px;
		font-weight: 500;
	}

	.password_cont .but .cancel {
		border-radius: 5px;
		border: 0.5px solid #aaa;
		background: #fff;
		color: #aaa;
		font-size: 18px;
		font-weight: 500;
	}

	@media only screen and (max-width: 1800px) {
		.password_cont .name {
			color: #000;
			font-size: 16px;
		}

		.password_cont .but .submit {
			border-radius: 5px;
			background: #1890ff;
			color: #fff;
			font-size: 16px;
			font-weight: 500;
		}
	}

	@media only screen and (max-width: 768px) {
		.box {
			.sidebar {
				width: 0;
			}

			.password {
				width: 100%;

				.password_cont {
					.but {
						.submit {
							width: 20vw;
							font-size: 14px;
							height: 30px;
							line-height: 30px;
							height: 30px;
						}

						.cancel {
							width: 20vw;
							font-size: 14px;
							height: 30px;
							line-height: 30px;
							height: 30px;
						}
					}
				}

				.password_cont .el-input {
					width: 70vw;
				}

				.password_cont {
					width: 70vw;
					padding: 20px 30px;
					margin: 30px auto;
				}
			}
		}

		.box .password .password_cont {
			width: 70vw;
			padding: 10px;
			margin: 10px auto;
		}
	}
</style>