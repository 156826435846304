<template>
	<div>
		<Header></Header>
		<headmenu></headmenu>
		<div class="service">
			<div class="title">
				<h1>会员服务</h1>
				<div class="line"></div>
			</div>
			<div class="text">
				<span>
					<div v-html="details"></div>
				</span>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import {
		serviceDetails
	} from "../api/index";
	import Header from "../components/header.vue";
	import Footer from "../components/footer.vue";
	import headmenu from "../components/headmenu.vue";
	export default {
		data() {
			return {
				details: [],
			};
		},
		props: ['id'],
		components: {
			Header,
			Footer,
			headmenu
		},
		mounted() {
			this.serviceDetails();
		},
		methods: {
			serviceDetails() {
				this.Loading(".content");
				serviceDetails({
					id: this.$route.params.id
				}).then((res) => {
					this.isloading.close();
					// console.log("会员服务详情", res);
					if (res.data.code == 1) {
						this.details = res.data.data.content;
					} else {}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.service {
		width: 70%;
		border-radius: 5px;
		margin: 50px auto 60px;
		background-color: #fff;

		.title {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;

			h1 {
				font-size: 32px;
			}

			.line {
				margin-top: -5px;
				width: 50px;
				text-align: center;
				border-bottom: 4px solid #1890ff;
			}
		}

		.text {
			padding: 30px 70px;
			// font-size: 18px;

			span {
				// font-size: 18px;
				// line-height: 34px;
			}
		}
	}

	@media only screen and (max-width: 1366px) {
		.service .title h1 {
			font-size: 24px;
		}

		// .service .text span {
		// 	font-size: 16px;
		// 	line-height: 30px;
		// }

		// .service .text {
		// 	padding: 20px 20px;
		// 	font-size: 16px;
		// }

		.service {
			width: 1000px;
			margin: 20px auto 30px;
		}
	}

	@media only screen and (max-width: 1100px) {
		.service {
			width: 750px;
			margin: 20px auto 30px;
		}

		// .service .text {
		// 	padding: 10px 10px;
		// 	font-size: 14px;
		// }

		// .service .text span {
		// 	font-size: 14px;
		// 	line-height: 30px;
		// 	padding: 0px;
		// }
	}

	@media only screen and (max-width: 768px) {

		.service .title h1 {
			font-size: 18px;
		}

		.service {
			width: 98%;
			margin: 70px auto 30px;
		}
	}
</style>