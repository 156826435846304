<template>
	<div class="content">
		<div class="head">
			<!-- <div class="photo">
				<img :src="avatar" alt="" />
			</div> -->
			<div class="name">{{ username }}</div>
			<!-- <div class="text">
				<img src="../assets/wicon_1.png" alt="" />{{ company }}
			</div> -->
		</div>
		<div class="list_cont">
			<el-row class="tac">
				<el-col :span="12">
					<el-menu :default-active="this.$route.path" background-color="#001529" class="el-menu-vertical-demo"
						@open="handleOpen" @close="handleClose" :router="true">
						<el-menu-item index="/message">
							<span slot="title">消息中心</span>
						</el-menu-item>
						<el-submenu index="2" class="sub2">
							<template class="title" slot="title">
								<span>会员信息更改</span>
							</template>
							<el-menu-item index="/editapply">基本信息修改</el-menu-item>
							<el-menu-item index="/password">密码修改</el-menu-item>
						</el-submenu>
						<el-submenu index="3" class="sub2">
							<template class="title" slot="title">
								<span>会员缴费</span>
							</template>
							<el-menu-item index="/pay">会员缴费</el-menu-item>
							<el-menu-item index="/pay_list">缴费列表</el-menu-item>
						</el-submenu>
						<el-menu-item index="/certificate">
							<span slot="title">会员证书</span>
						</el-menu-item>
						<el-menu-item index="/quit">
							<span slot="title">会员退会</span>
						</el-menu-item>
						<el-menu-item index="/declare">
							<span slot="title">项目申报</span>
						</el-menu-item>
					</el-menu>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
	export default {
		name: "sidebar",
		data() {
			return {
				company: localStorage.getItem("company"),
				username: localStorage.getItem("username"),
				avatar: localStorage.getItem("avatar"),
			};
		},
		props: {},
		methods: {
			goto(url) {
				this.$router.push(url);
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
		},
	};
</script>

<style scoped lang="scss">
	.content {
		min-height: 100vh;
		background-color: #001529;
	}

	.content .head {
		text-align: center;
		padding: 67px 0 54px;
		background: #00284d;
	}

	.content .head .photo {
		width: 120px;
		margin: 0 auto;
		margin-bottom: 15px;
	}

	.content .head .photo img {
		vertical-align: middle;
		margin-top: -3px;
		border-radius: 50%;
		width: 100%;
	}

	.content .head .name {
		margin-bottom: 13px;
		color: #fff;
		font-size: 20px;
		font-weight: 500;
		line-height: 80.523%;
		/* 16.105px */
	}

	.content .head .text {
		color: #fff;
		font-size: 14px;
	}

	.content .head .text img {
		vertical-align: middle;
		margin-top: -3px;
		margin-right: 9px;
		line-height: 80.523%;
		/* 11.273px */
	}

	.content .list_cont {
		background: linear-gradient(180deg, #001529 0%, #032341 100%);
		padding-top: 13px;
	}

	.content .list_cont .el-col-12 {
		width: 100.5%;
	}

	.content .list_cont .el-menu {
		border-right: solid 1px #e6e6e6;
		list-style: none;
		position: relative;
		margin: 0;
		padding-left: 0;
		background: linear-gradient(180deg, #001529 0%, #032341 100%);
		text-align: center;
	}

	.content .list_cont .el-menu-item {
		height: 64px;
		line-height: 64px;
		text-align: center;
		color: #fff;
		font-size: 22px;
	}

	.content .list_cont .sub2 .el-menu-item {
		height: 64px;
		line-height: 64px;
		text-align: center;
		color: #fff;
		font-size: 18px;
	}

	.content .list_cont .el-menu-item.is-active {
		background: linear-gradient(91deg, #2ba3e8 0.45%, #1e2d40 99.65%);
	}

	/deep/.el-submenu__title {
		font-size: 14px;
		color: #303133;
		padding: 0 20px;
		cursor: pointer;
		transition: border-color 0.3s, background-color 0.3s, color 0.3s;
		box-sizing: border-box;
		height: 64px !important;
		line-height: 64px !important;
	}

	.content .list_cont .el-submenu__title span {
		text-align: center;
		color: #fff;
		font-size: 22px;
	}

	.el-submenu .el-menu-item {
		min-width: auto;
	}

	/deep/ .el-submenu__title i {
		color: #fff !important;
	}

	/deep/ .el-submenu__icon-arrow {
		position: absolute;
		top: 45%;
		right: 50px;
		margin-top: -7px;
		transition: transform 0.3s;
		font-size: 23px;
	}

	@media only screen and (max-width: 1500px) {
		.cate_cont .search .input .text {
			font-size: 18px;
			width: 100px;
		}

		.content .list_cont .el-menu-item[data-v-19f8877c] {
			font-size: 16px;
		}

		.content .list_cont .el-submenu__title span[data-v-19f8877c] {
			font-size: 16px;
		}

		[data-v-19f8877c] .el-submenu__icon-arrow {
			font-size: 16px;
			position: absolute;
			top: 49%;
			right: 44px;
		}

		[data-v-19f8877c] .el-submenu__title {
			height: 54px !important;
			line-height: 54px !important;
		}

		.content .list_cont .el-menu-item[data-v-19f8877c] {
			height: 54px;
			line-height: 54px;
			font-size: 16px;
		}

		/deep/.el-submenu__title {
			height: 54px !important;
			line-height: 54px !important;
		}

		/deep/.el-submenu__title {
			font-size: 14px;
			color: #303133;
			padding: 0 20px;
			cursor: pointer;
			transition: border-color 0.3s, background-color 0.3s, color 0.3s;
			box-sizing: border-box;
			height: 54px !important;
			line-height: 54px !important;
		}

		/deep/.content .list_cont .sub2 .el-menu-item[data-v-19f8877c] {
			font-size: 14px;
		}
	}

	@media only screen and (max-width: 768px) {
		.content {
			display: none;
		}
	}
</style>