<template>
	<div class="">
		<div class="kefu" @click="tokefu">
			<img src="../assets/chat.png" alt="" />
		</div>

		<el-dialog title="在线留言" center :visible.sync="dialogFormVisible">
			<el-form :model="form" :rules="rules" ref="ruleForm">
				<el-form-item label="" label-width="38%">
					<el-radio-group v-model="form.type">
						<el-radio :label="1">咨询</el-radio>
						<el-radio :label="2">建议</el-radio>
						<el-radio :label="3">其他</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
					<el-input v-model="form.name" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="电话" :label-width="formLabelWidth" prop="phone">
					<el-input v-model="form.phone" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
					<el-input v-model="form.email" placeholder="请输入"></el-input>
				</el-form-item>
				<el-form-item label="意见反馈" :label-width="formLabelWidth" prop="content">
					<el-input type="textarea" v-model="form.content" placeholder="请输入"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="contactUs('ruleForm')">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		contactUs
	} from "../api/index";
	export default {
		name: "chat",
		data() {
			return {
				dialogFormVisible: false,
				form: {
					title: "留言",
					type: 1,
					name: "",
					phone: "",
					email: "",
					content: "",
				},
				formLabelWidth: "120px",
				rules: {
					phone: [{
						pattern: /^1((34[0-8])|(8\d{2})|(([35][0-35-9]|4[579]|66|7[35678]|9[1389])\d{1}))\d{7}$/,
						message: "手机号码格式不正确",
						trigger: "blur",
					}, ],
					email: [{
						pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
						message: "邮箱格式不正确",
						trigger: "blur",
					}, ],
				},
			};
		},
		methods: {
			tokefu() {
				this.dialogFormVisible = true;
			},
			//在线留言
			contactUs(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						contactUs({
							...this.form,
						}).then((res) => {
							// console.log("在线留言", res);
							if (res.data.code == 1) {
								this.$message.success(res.data.msg);
								this.form = {};
							} else {
								this.$message.error()(res.data.msg);
							}
						});
					} else {
						return false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.kefu {
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 45%;
	}

	/deep/.el-dialog--center .el-dialog__body {
		padding: 0 25px 30px !important;
	}

	/deep/.el-dialog {
		width: 50%
	}

	@media only screen and (max-width: 768px) {
		/deep/.el-dialog {
			width: 90% !important
		}
		/deep/.el-form-item__content{
			margin-left: 0 !important;
			text-align: center;
		}
		/deep/.el-form-item__label {
		    text-align: left;
		    font-size: 12px;
		    line-height: 30px;
		}
		/deep/.el-form-item {
		    margin-bottom: 0px;
		}
	}
</style>