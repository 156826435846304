<template>
	<div class="box">
		<div class="sidebar">
			<sidebar></sidebar>
		</div>
		<div class="declare">
			<Header></Header>
			<div class="declare_cont">
				<div class="title">2023年中国电子元器件骨干企业基本信息表</div>
				<div class="introduce">
					<div class="name">
						<img src="../assets/wicon_5.png" alt="">填表说明
					</div>
					<div class="word">
						感谢您参与2022年1-12月中国电子元器件行业统计年报工作。
					</div>
					<div class="word">
						请贵司确定一名联系人，所有资料反馈和联系等均通过联系人与贵司联系。谢谢！
					</div>
				</div>
				<div class="text">
					<img src="../assets/wicon_6.png" alt="">2023年中国电子元器件骨干企业基本信息表下载
				</div>
				<div class="down">单位申报意见表下载</div>
				<div class="text">
					<img src="../assets/wicon_6.png" alt="">2023年中国电子元器件骨干企业基本信息表上传
				</div>
				<div class="but"><img src="../assets/wicon_7.png" alt="">上传</div>
				<div class="relation">
					<div class="list">联系人: 范洪慧</div>
					<div class="list">电话:010-68871587、18531561206</div>
					<div class="list">传 真:010-68637639</div>
					<div class="list">Email: icceca@ic-ceca.org.cn, fhh@ic-ceca.org.cn</div>
				</div>
				<div class="cha"><img src="../assets/wicon_4.png" alt=""></div>
			</div>
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
	import Header from "../components/header2.vue";
	import Footer from "../components/footer2.vue";
	import sidebar from "../components/sidebar.vue";
	export default {
		components: {
			Header,
			Footer,
			sidebar
		},
	};
</script>

<style scoped>
	body {
		padding: 0;
		margin: 0;
	}

	.box {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap
	}

	.sidebar {
		width: 18%;
	}

	.declare {
		width: 82%;
		background: linear-gradient(180deg, rgba(171, 198, 246, 0.27) 0%, rgba(216, 224, 232, 0.10) 100%);
	}

	.declare_cont {
		height: auto;
		border-radius: 5px;
		background: #FFF;
		margin: 35px 72px 91px 82px;
		padding: 47px 101px 67px 101px;
		position: relative;
	}

	.declare_cont .cha {
		position: absolute;
		top: -15px;
		right: -35px;
	}

	.declare_cont .title {
		margin-bottom: 50px;
		color: #000;
		text-align: center;
		font-size: 24px;
		font-weight: 500;
	}

	.declare_cont .introduce {
		padding: 27px 80px 21px 48px;
		border-radius: 20px;
		background: #F6F9FF;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
		margin-bottom: 20px;
	}

	.declare_cont .introduce .name {
		color: #000;
		font-size: 18px;
		font-weight: 500;
		line-height: 190%;
		/* 34.2px */
		text-transform: lowercase;
	}

	.declare_cont .introduce .name img {
		margin-right: 10px;
		margin-top: -3px;
		vertical-align: middle;
	}

	.declare_cont .introduce .word {
		color: #000;
		font-size: 18px;
		line-height: 179%;
		/* 32.22px */
		text-transform: lowercase;
		margin-left: 30px;
	}

	.declare_cont .text {
		color: #000;
		font-size: 22px;
		text-transform: lowercase;
		margin-bottom: 10px;
	}

	.declare_cont .text img {
		margin-right: 10px;
		margin-top: -3px;
		vertical-align: middle;
	}

	.declare_cont .down {
		color: #1890FF;
		font-size: 18px;
		font-weight: 500;
		text-decoration-line: underline;
		text-transform: lowercase;
		margin-bottom: 10px;
		margin-left: 30px;
	}

	.declare_cont .but {
		width: 334px;
		height: 50px;
		line-height: 50px;
		flex-shrink: 0;
		border-radius: 5px;
		background: #1890FF;
		text-align: center;
		color: #FFF;
		font-size: 18px;
		font-weight: 500;
		cursor: pointer;
		margin-top: 20px;
	}

	.declare_cont .but img {
		margin-right: 10px;
		margin-top: -3px;
		vertical-align: middle;
	}

	.declare_cont .relation {
		border-radius: 20px;
		background: #F6F9FF;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
		padding: 40px 148px 40px 195px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 100px;
	}

	.declare_cont .relation .list {
		width: 50%;
		margin-bottom: 5px;
		color: #000;
		font-size: 17px;
		text-transform: lowercase;
	}

	@media only screen and (max-width:1800px) {
		.declare_cont .title {
			margin-bottom: 50px;
			color: #000;
			text-align: center;
			font-size: 20px;
			font-weight: 500;
		}

		.declare_cont .introduce .word {
			color: #000;
			font-size: 16px;
			line-height: 179%;
			text-transform: lowercase;
			margin-left: 30px;
		}

		.declare_cont .introduce .name {
			color: #000;
			font-size: 16px;
			font-weight: 500;
			line-height: 190%;
			text-transform: lowercase;
		}

		.declare_cont .text {
			color: #000;
			font-size: 18px;
			text-transform: lowercase;
			margin-bottom: 10px;
		}

		.declare_cont .relation {
			border-radius: 20px;
			background: #F6F9FF;
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
			padding: 40px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 100px;
		}
	}

	@media only screen and (max-width:1600px) {
		.declare_cont {
			height: auto;
			margin: 35px 72px 0px 82px;
		}

		.declare_cont .relation {
			border-radius: 20px;
			background: #F6F9FF;
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
			padding: 40px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-top: 20px;
		}

		.declare_cont .title {
			margin-bottom: 20px;
			color: #000;
			text-align: center;
			font-size: 20px;
			font-weight: 500;
		}
	}

	@media only screen and (max-width:1500px) {
		.declare_cont {
			margin: 30px 30px 0;
			padding: 30px;
		}
		.declare_cont .introduce {
		    padding: 20px;
		    border-radius: 20px;
		    background: #F6F9FF;
		    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
		    margin-bottom: 20px;
		}
		.declare_cont .relation {
		    border-radius: 20px;
		    background: #F6F9FF;
		    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
		    padding: 20px;
		    display: flex;
		    justify-content: space-between;
		    flex-wrap: wrap;
		    margin-top: 20px;
		}
		.declare_cont .cha {
		    position: absolute;
		    top: -15px;
		    right: -15px;
		}
	}
</style>