import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import store from './store'
import { mixins } from "./mixin/index";
Vue.mixin(mixins);
Vue.use(ElementUI);
//引入导出的路由
import router from './router/index.js'
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
