<template>
  <div class="box">
    <div class="sidebar">
      <sidebar></sidebar>
    </div>
    <div class="cate">
      <Header></Header>
      <div class="cate_cont">
        <div class="search">
          <div class="input">
            <div class="text">年度：</div>
            <el-input
              placeholder="请输入"
              v-model="year"
              clearable
              @change="changeYear"
            ></el-input>
          </div>
          <div class="input">
            <div class="text">状态：</div>
            <el-select
              v-model="status"
              class="select"
              placeholder="请选择"
              @change="changeStatus"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="tabel">
          <div class="head">
            <div class="year">年度</div>
            <div class="make">缴费状态</div>
            <div class="time2">缴费时间</div>
            <div class="state">开票状态</div>
            <div class="time">开票时间</div>
            <div class="handle">操作</div>
          </div>
          <div class="tabel_cont">
            <div
              class="list"
              v-for="(item, index) in listinfo.data"
              :key="index"
            >
              <div class="year">{{ item.year }}</div>
              <div class="make">{{ item.status_name }}</div>
              <div class="time2">{{ item.create_time_text }}</div>
              <div class="state">
                {{
                  item.invo_status == 0 || item.status_name == "未审核"
                    ? "未开票"
                    : "已开票"
                }}
              </div>
              <div class="time">{{ item.invo_time_text }}</div>
              <div class="handle">
                <div class="text" @click="goto('/pay')">上传缴费凭证</div>
                <div class="text" @click="openinvo(item)">开票信息</div>
                <div class="text" @click="download(item.pay_url)">发票下载</div>
              </div>
            </div>
          </div>
          <div class="paging">
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page="listinfo.current_page"
              @current-change="currentchange"
              :total="listinfo.total"
              :page-size="8"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
    <el-dialog
      title="开票信息"
      center
      :visible.sync="dialogFormVisible"
      width="50%"
    >
      <el-form :model="form" ref="ruleform">
        <el-form-item label="联系人:" :label-width="formLabelWidth">
          <el-input
            v-model="info.name"
            placeholder="未填写"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人手机:" :label-width="formLabelWidth">
          <el-input
            v-model="info.phone"
            placeholder="未填写"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="发票抬头:" :label-width="formLabelWidth">
          <el-input
            v-model="info.lookup"
            placeholder="未填写"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位税号:" :label-width="formLabelWidth">
          <el-input
            v-model="info.duty_code"
            placeholder="未填写"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册地址:" :label-width="formLabelWidth">
          <el-input
            v-model="info.address"
            placeholder="未填写"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="注册电话:" :label-width="formLabelWidth">
          <el-input
            v-model="info.tel"
            placeholder="未填写"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="开户银行:" :label-width="formLabelWidth">
          <el-input
            v-model="info.bank"
            placeholder="未填写"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="银行账号:" :label-width="formLabelWidth">
          <el-input
            v-model="info.bank_code"
            placeholder="未填写"
            :disabled="true"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogFormVisible = fales">关闭</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { getPayList } from "../api/index";
import Header from "../components/header2.vue";
import Footer from "../components/footer.vue";
import sidebar from "../components/sidebar.vue";
export default {
  components: {
    Header,
    Footer,
    sidebar,
  },
  data() {
    return {
      options: [
        {
          value: "0",
          label: "未审核",
        },
        {
          value: "1",
          label: "审核",
        },
        {
          value: "2",
          label: "驳回",
        },
      ],
      year: "",
      status: "",
      page: 1,
      listinfo: {},
      dialogFormVisible: false,
      form: {
        year: "",
        name: "",
        phone: "",
        lookup: "",
        duty_code: "",
        address: "",
        tel: "",
        bank: "",
        bank_code: "",
      },
      info: {},
      formLabelWidth: "120px",
    };
  },
  mounted() {
    this.getPayList();
  },
  methods: {
    download(url) {
      if (url) {
        const link = document.createElement("a");
        link.href = url;
        link.download = "发票.docx";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
      } else {
        this.$message.error("未开发票");
      }
    },
    goto(url) {
      this.$router.push(url);
    },
    //开票信息
    openinvo(item) {
      this.info = item;
      this.dialogFormVisible = true;
    },
    getPayList() {
      this.Loading(".tabel_cont");
      getPayList({
        page: this.page,
        limit: 8,
        status: this.status,
        year: this.year,
      }).then((res) => {
        this.isloading.close();
        if (res.data.code == 1) {
          this.listinfo = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    changeYear(e) {
      // console.log('change',e)
      this.page = 1;
      this.year = e;
      this.getPayList();
    },
    changeStatus(e) {
      // console.log('change',e)
      this.page = 1;
      this.status = e;
      this.getPayList();
    },
    currentchange(page) {
      // console.log(page)
      this.page = page;
      this.getPayList();
    },
  },
};
</script>

<style scoped lang="scss">
body {
  padding: 0;
  margin: 0;
}

.box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sidebar {
  width: 18%;
  height: 100%;
}

.cate {
  width: 82%;
  background: linear-gradient(
    180deg,
    rgba(171, 198, 246, 0.27) 0%,
    rgba(216, 224, 232, 0.1) 100%
  );
}

.cate_cont {
  height: auto;
  border-radius: 5px;
  background: #fff;
  margin: 35px 72px 91px 82px;
  padding: 42px 61px 67px 59px;
  position: relative;
}

.cate_cont .search {
  display: flex;
}

.cate_cont .search .input {
  display: flex;
  width: 40%;
  margin-right: 5%;
  align-items: center;
}

.cate_cont .search .select {
  display: flex;
  width: 100%;
  margin-right: 5%;
  align-items: center;
}

.cate_cont .search .input .text {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  width: 100px;
}

.cate_cont .search .input .el-input__inner {
  height: 60px;
  line-height: 60px;
  border-radius: 5px;
  background: #fff;
  padding-left: 20px;
  color: #aaa;
  font-size: 16px;
}

.cate_cont .tabel {
  margin-top: 50px;
  box-shadow: 1px 1px 4px #f5f5f5;
  padding-bottom: 50px;
}

.cate_cont .tabel .head {
  padding: 0 70px 0 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 55px;
  line-height: 55px;
  background: #f6f6f6;
  color: #000;
  text-align: center;
  font-size: 18px;
}

.cate_cont .tabel .head .year {
  width: 10%;
  text-align: center;
}

.cate_cont .tabel .head .make {
  width: 10%;
  text-align: center;
}

.cate_cont .tabel .head .time2 {
  width: 20%;
  text-align: center;
}

.cate_cont .tabel .head .state {
  width: 10%;
  text-align: center;
}

.cate_cont .tabel .head .time {
  width: 20%;
  text-align: center;
}

.cate_cont .tabel .head .handle {
  width: 30%;
  text-align: center;
}

.cate_cont .tabel .tabel_cont .list {
  margin: 0 70px 0 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 50px;
  align-items: center;
  background: #fff;
  color: #000;
  text-align: center;
  font-size: 18px;
  border-bottom: 1px solid #f4f4f4;
}

.cate_cont .tabel .tabel_cont {
  margin-bottom: 120px;
}

.cate_cont .tabel .tabel_cont .year {
  width: 10%;
  text-align: center;
}

.cate_cont .tabel .tabel_cont .make {
  width: 10%;
  text-align: center;
}

.cate_cont .tabel .tabel_cont .time2 {
  width: 20%;
  text-align: center;
}

.cate_cont .tabel .tabel_cont .state {
  width: 10%;
  text-align: center;
}

.cate_cont .tabel .tabel_cont .time {
  width: 20%;
  text-align: center;
}

.cate_cont .tabel .tabel_cont .handle {
  width: 30%;
  text-align: center;
  display: flex;
}

.cate_cont .tabel .tabel_cont .handle .text {
  cursor: pointer;
  color: #1890ff;
  margin: 0 20px;
  font-size: 18px;
  font-weight: 500;
}

.cate_cont .tabel .paging {
  display: flex;
  justify-content: center;
  margin-top: 83px;

  /deep/.el-pagination.is-background .el-pager li {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }

  /deep/.el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }

  /deep/.el-pagination.is-background .btn-prev:disabled {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }

  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    border: 1px solid #1890ff;
    background-color: #fff;
    color: #1890ff;
    height: 30px;
  }

  /deep/.el-pagination.is-background .btn-prev {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }
}

@media only screen and (max-width: 1800px) {
  .cate_cont .tabel .tabel_cont .handle .text {
    margin: 0 10px;
    font-size: 16px;
  }

  .cate_cont .tabel .tabel_cont .time {
    font-size: 16px;
  }

  .cate_cont .tabel .tabel_cont .state {
    font-size: 16px;
  }

  .cate_cont .tabel .tabel_cont .time2 {
    font-size: 16px;
  }

  .cate_cont .tabel .tabel_cont .make {
    font-size: 16px;
  }

  .cate_cont .tabel .tabel_cont .year {
    font-size: 16px;
  }

  .cate_cont .tabel .head .year {
    font-size: 16px;
  }

  .cate_cont .tabel .head .make {
    font-size: 16px;
  }

  .cate_cont .tabel .head .time2 {
    font-size: 16px;
  }

  .cate_cont .tabel .head .state {
    font-size: 16px;
  }

  .cate_cont .tabel .head .time {
    font-size: 16px;
  }

  .cate_cont .tabel .head .handle {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1600px) {
  .cate_cont {
    height: auto;
    border-radius: 5px;
    background: #fff;
    margin: 30px 30px 0;
    padding: 30px;
  }

  .cate_cont .search .input .text {
    font-size: 16px;
    width: 65px;
  }

  .cate_cont .search .input .el-input__inner {
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    background: #fff;
    padding-left: 20px;
    color: #aaa;
    font-size: 16px;
  }

  .cate_cont .tabel {
    margin-top: 20px;
    box-shadow: 1px 1px 4px #f5f5f5;
    padding-bottom: 20px;
  }

  .cate_cont .tabel .paging {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1500px) {
  .cate_cont {
    height: auto;
    border-radius: 5px;
    background: #fff;
    margin: 30px 30px 0;
    padding: 30px;
  }

  .cate_cont .search .input .text {
    font-size: 16px;
    width: 65px;
  }

  .cate_cont .search .input .el-input__inner {
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    background: #fff;
    padding-left: 20px;
    color: #aaa;
    font-size: 16px;
  }

  .cate_cont .tabel {
    margin-top: 20px;
    box-shadow: 1px 1px 4px #f5f5f5;
    padding-bottom: 20px;
  }

  .cate_cont .tabel .paging {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .box {
    .sidebar {
      width: 0;
    }

    .cate {
      width: 100%;
      height: 100vh;

      .cate_cont {
        overflow: scroll;
        overflow-x: auto;

        .submit {
          width: auto;
        }

        .search {
          width: 1000px;
        }

        .tabel {
          width: 1000px;
        }
      }
    }
  }

  .cate_cont {
    height: auto;
    border-radius: 5px;
    background: #fff;
    margin: 10px 10px 0;
    padding: 10px;
  }
}
</style>