export const mixins = {
    data() {
      return {
        isloading:{}
      };
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        Loading(dom){
            this.isloading = this.$loading({
              target:document.querySelector(dom),
              lock: true,
              text: "Loading",
              spinner: "el-icon-loading",
            });
          },
    },
  };
  