<template>
  <div>
    <Header></Header>
    <headmenu></headmenu>
    <div class="directory">
      <div class="head">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          clearable
        >
          <div class="title">单位查询</div>
          <el-form-item label="">
            <!-- <el-cascader
              size="large"
              :options="options"
              v-model="province"
              @change="handleChange"
              placeholder="省市区"
            >
            </el-cascader> -->
            <el-select
              v-model="formInline.province"
              placeholder="全国"
              clearable
              @change="prochange"
            >
              <el-option
                v-for="(item, index) in provinceList"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="formInline.group"
              placeholder="协会全体会员"
              @change="derchange"
              clearable
            >
              <el-option
                v-for="(item, index) in department"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select
              v-model="level.title"
              placeholder="会员等级"
              clearable
              @change="levchange"
            >
              <el-option
                v-for="(item, index) in level"
                :key="index"
                :label="item.title"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-input
              v-model="formInline.keyword"
              placeholder="请输入企业名称关键字"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item> -->
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <!-- </el-form-item> -->
        </el-form>
      </div>
      <div class="lists">
        <div class="list" v-if="listinfo.data">
          <div class="item" v-for="(item, index) in listinfo.data" :key="index">
            <span>{{ item.company }}</span>
          </div>
        </div>
        <div v-else style="margin: 100px auto">
          <span>没有更多了</span>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="listinfo.current_page"
          @current-change="currentchange"
          :total="listinfo.total"
          :page-size="formInline.limit"
        >
        </el-pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import {
  memberDirectory,
  getOtherSelect,
  areaAll,
  getLevelByDepart,
} from "../api/index";
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import headmenu from "../components/headmenu.vue";
import { pcaTextArr } from "element-china-area-data";
export default {
  data() {
    return {
      formInline: {
        limit: 40,
        page: 1,
        province: "",
        level: "",
        group: "",
        keyword: "",
      },
      provinceList: [],
      level: [
        {
          id: 0,
          title: "一般会员单位",
          create_time_text: "",
        },
      ],
      department: [],
      listinfo: {},
      options: pcaTextArr,
      selectedOptions: [],
    };
  },
  components: {
    Header,
    Footer,
    headmenu,
  },
  mounted() {
    this.formInline.page = 1;
    this.memberDirectory();
    this.getOtherSelect();
    this.areaAll();
  },
  methods: {
    //省市区
    // handleChange(value) {
    //   // console.log('value',value);
    //   this.selectedOptions = [[value[0]], [value[1]], [value[2]]];
    //   this.province = this.selectedOptions.join("/");
    //   // console.log('province',this.province);
    // },
    areaAll() {
      areaAll().then((res) => {
        // console.log(res);
        this.provinceList = res.data.data.province;
      });
    },
    onSubmit() {
      this.formInline.page = 1;
      this.memberDirectory();
    },
    //获取所属部门
    getOtherSelect() {
      getOtherSelect().then((res) => {
        // console.log(res);
        if (res.data.code == 1) {
          this.department = res.data.data.department;
          this.level = res.data.data.level;
          this.level.push({
            id: 0,
            title: "一般会员单位",
            create_time_text: "",
          });
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    derchange(e) {
      this.formInline.page = 1;
      this.formInline.level = "";
      // console.log("e", e);
      this.memberDirectory();
      if (e) {
        this.getLevelByDepart(e);
      } else {
        this.getOtherSelect();
      }
    },
    prochange() {
      this.formInline.page = 1;
      this.memberDirectory();
    },
    levchange(e) {
      this.formInline.page = 1;
      // console.log('666666',e)
      this.formInline.level = e;

      this.memberDirectory();
    },
    //获取会员等级
    getLevelByDepart(id) {
      getLevelByDepart({
        id: id,
      }).then((res) => {
        // console.log(res);
        if (res.data.code == 1) {
          // console.log("1111111111", res.data);
          // this.department = res.data.data.department;
          //this.level = this.level.concat(res.data.data);
          this.level = res.data.data;
          this.level.push({
            id: 0,
            title: "一般会员单位",
            create_time_text: "",
          });
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    memberDirectory() {
      this.Loading(".directory");
      memberDirectory({
        ...this.formInline,
      }).then((res) => {
        this.isloading.close();
        // console.log("111111111111", res);
        if (res.data.code == 1) {
          // this.$message.success(res.data.msg);
          //   this.formInline = {};
          this.listinfo = res.data.data.list;
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    currentchange(page) {
      // console.log(page)
      this.formInline.page = page;
      this.formInline.limit = 40;
      this.memberDirectory();
    },
  },
};
</script>

<style lang="scss" scoped>
.directory {
  width: 70%;
  border-radius: 5px;
  margin: 50px auto 60px;
  background-color: #fff;

  .head {
    // border: 1px solid red;
    width: 100%;
    height: 130px;
    background-color: #1890ff;
    border-radius: 5px;
    display: flex;
    align-items: center;

    // justify-content: center;
    // width: 100%;
    .title {
      width: 9%;
      font-size: calc(100vw * 16 / 1920);
      color: #fff;
    }

    .el-form--inline {
      // border: 1px solid red;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      margin: 0 auto;
    }

    .el-form--inline .el-form-item {
      // border: 1px solid red;
      // display: flex;
      width: 20%;
      margin-bottom: 0;
      margin-right: 20px;
    }

    .el-button--primary {
      width: 120px;
      height: 38px;
      background-color: #ff9d0b;
    }

    /deep/.el-form--inline .el-form-item__label {
      color: #ffffff;
      font-size: 16px;
    }
  }

  .lists {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding-bottom: 30px;

    .list {
      background-color: #fff;
      //   min-height: 250px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: start;
      width: 85%;
      margin: 15px auto;

      .item {
        width: 24%;
        height: 65px;
        border-radius: 5px;
        margin-right: 5px;
        margin-top: 5px;
        background-color: #f4f9fe;
        display: flex;
        align-items: center;
        // justify-content: start;

        span {
          width: 85%;
          text-align: center;
          padding: 5px 15px;
          font-size: 14px !important;
          color: #383838;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
  }
}

/deep/.el-pagination.is-background .el-pager li {
  background-color: #fff;
  color: #898989;
  border: 1px solid #d9d9d9;
  font-family: PingFang SC;
  font-weight: 400;
  height: 30px;
}

/deep/.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: #fff;
  color: #898989;
  border: 1px solid #d9d9d9;
  font-family: PingFang SC;
  font-weight: 400;
  height: 30px;
}

/deep/.el-pagination.is-background .btn-prev:disabled {
  background-color: #fff;
  color: #898989;
  border: 1px solid #d9d9d9;
  font-family: PingFang SC;
  font-weight: 400;
  height: 30px;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  border: 1px solid #1890ff;
  background-color: #fff;
  color: #1890ff;
  height: 30px;
}

/deep/.el-pagination.is-background .btn-prev {
  background-color: #fff;
  color: #898989;
  border: 1px solid #d9d9d9;
  font-family: PingFang SC;
  font-weight: 400;
  height: 30px;
}

@media only screen and (max-width: 1366px) {
  .directory .lists .list .item {
    width: 24%;
    border-radius: 5px;
    margin-right: 8px;
    margin-top: 10px;
    background-color: #f4f9fe;
    display: flex;
    align-items: center;
    // justify-content: start;
  }

  .directory {
    width: 1000px;
    margin: 20px auto 30px;
  }

  .directory .lists .list .item span {
    text-align: center;
    padding: 10px;
    height: 40px;
    font-size: 14px;
    color: #383838;
    display: grid;
    align-items: center;
    // white-space: nowrap;
    // overflow: hidden;
    // width: 85%;
    // text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 1100px) {
  .directory {
    width: 750px;
    margin: 20px auto 30px;
  }

  .directory .lists .list .item {
    width: 32.1%;
    border-radius: 5px;
    margin-right: 7px;
    margin-top: 10px;
    background-color: #f4f9fe;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .directory .head .el-form--inline {
    flex-wrap: wrap;
  }

  .directory .head .title {
    width: 30%;
    font-size: 14px;
    color: #fff;
  }

  .directory .head .el-form--inline .el-form-item {
    width: 30%;
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .directory .head .el-form--inline {
    width: 500px;
    margin: 0 auto;
    justify-content: space-between;
  }

  .directory .head .el-button--primary {
    width: 30%;
    height: 40px;
    background-color: #ff9d0b;
    margin-top: -10px;
  }
}

@media only screen and (max-width: 768px) {
  .directory {
    width: 98%;
    margin: 70px auto 30px;
  }

  .directory .head .el-form--inline {
    width: 350px;
    margin: 0 auto;
    justify-content: space-between;
  }

  .directory .lists .list {
    background-color: #fff;
    min-height: 250px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: start;
    width: 98%;
    margin: 0px 0px;
  }

  .directory .lists .list .item {
    width: 31.3%;
    border-radius: 5px;
    margin-right: 0px;
    margin: 0 1%;
    margin-top: 10px;
    background-color: #f4f9fe;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .directory .lists .list .item span {
    text-align: center;
    padding: 10px;
    height: 50px;
    font-size: 12px;
    color: #383838;
  }
  .el-pagination {
    white-space: nowrap;
    padding: 2px 5px;
    color: #303133;
    font-weight: 700;
    margin-top: 20px;
  }
}
</style>