<template>
  <div>
    <Header></Header>
    <headmenu></headmenu>
    <div class="sysdetail">
      <div class="title">
        <h1>{{ titles }}</h1>
        <div class="line"></div>
      </div>
      <div class="text">
        <span>
          <div v-html="details"></div>
        </span>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { getnoticeDetails } from "../api/index";
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import headmenu from "../components/headmenu.vue";
export default {
  data() {
    return {
      details: [],
      titles: "",
    };
  },
  props: ["id"],
  components: {
    Header,
    Footer,
    headmenu,
  },
  mounted() {
    this.getnoticeDetails();
  },
  methods: {
    getnoticeDetails() {
      // console.log(this.id)
      this.Loading(".detail");
      getnoticeDetails({
        id: this.$route.params.id,
      }).then((res) => {
        this.isloading.close();
        if (res.data.code == 1) {
          this.titles = res.data.data.message_title;
          this.details = res.data.data.message_content;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sysdetail {
  width: 70%;
  border-radius: 5px;
  margin: 50px auto 60px;
  background-color: #fff;

  .title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    // padding: 0 30px;
    text-align: center;

    h1 {
      font-size: 24px;
    }

    .line {
      margin-top: -5px;
      width: 50px;
      text-align: center;
      border-bottom: 4px solid #1890ff;
    }
  }

  .text {
    padding: 30px 70px;
    // font-size: 18px;

    span {
      // font-size: 18px;
      // line-height: 34px;
    }
  }
}
@media only screen and (max-width: 1750px) {
  .sysdetail {
    width: 70%;
    border-radius: 5px;
    margin: 50px auto 60px;
    background-color: #fff;

    .title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 20px 50px;

      text-align: center;

      h1 {
        font-size: 24px;
      }

      .line {
        margin-top: -5px;
        width: 50px;
        text-align: center;
        border-bottom: 4px solid #1890ff;
      }
    }

    .text {
      // padding: 50px 70px;
      // font-size: 18px;

      span {
        // font-size: 18px;
        // line-height: 34px;
        // word-wrap: break-word;
      }
    }
  }
}
@media only screen and (max-width: 1366px) {
  .sysdetail .title h1 {
    font-size: 22px;
  }
  .sysdetail .text span {
    // font-size: 16px;
    // line-height: 30px;
    // word-wrap: break-word;
  }

  .sysdetail .text {
    // padding: 20px 20px;
    // font-size: 16px;
  }

  .sysdetail {
    width: 1000px;
    margin: 20px auto 30px;
  }
}

@media only screen and (max-width: 1100px) {
  .sysdetail {
    width: 750px;
    margin: 20px auto 30px;
  }

  .sysdetail .text {
    //padding: 10px 10px;
    // font-size: 14px;
  }

  .sysdetail .text span {
    // font-size: 14px;
    // line-height: 30px;
    // padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .sysdetail .title h1 {
    font-size: 18px;
  }
  .sysdetail .text span {
    // font-size: 14px;
    // line-height: 30px;
    // padding: 0px;
    // word-wrap: break-word;
  }
  .sysdetail {
    width: 98%;
    margin: 70px auto 30px;
  }
}
</style>