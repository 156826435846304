<template>
  <div class="menu">
    <img v-if="isshow==false"
      src="../assets/banner_134.png"
      style="width: 20px; height: auto"
      @click="isshow = !isshow"
      alt=""
    />
    <img v-else
      src="../assets/wicon_4.png"
      style="width: 20px; height: auto"
      @click="isshow = !isshow"
      alt=""
    />
    <div class="list_cont" v-if="isshow">
      <el-row class="tac">
        <el-col :span="5">
          <el-menu
            :default-active="this.$route.path"
            background-color="#001529"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            :router="true"
          >
            <el-menu-item index="/message">
              <span slot="title">消息中心</span>
            </el-menu-item>
            <el-submenu index="2" class="sub2">
              <template class="title" slot="title">
                <span>会员信息更改</span>
              </template>
              <el-menu-item index="/editapply">基本信息修改</el-menu-item>
              <el-menu-item index="/password">密码修改</el-menu-item>
            </el-submenu>
            <el-submenu index="3" class="sub2">
              <template class="title" slot="title">
                <span>会员缴费</span>
              </template>
              <el-menu-item index="/pay">会员缴费</el-menu-item>
              <el-menu-item index="/pay_list">缴费列表</el-menu-item>
            </el-submenu>
            <el-menu-item index="/certificate">
              <span slot="title">会员证书</span>
            </el-menu-item>
            <el-menu-item index="/quit">
              <span slot="title">会员退会</span>
            </el-menu-item>
            <el-menu-item index="/declare">
              <span slot="title">项目申报</span>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      isshow: false,
    };
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.menu {
  z-index: 10;
  display: none;
  position: absolute;
  top: 20px;
  right: 10px;
  .list_cont {
    height: auto;
    width: auto;
    background: #075aa7;

    .el-menu {
      background: #075aa7;
    }
    /deep/.el-submenu__title {
      color: #fff;
    }
    .el-menu-item {
      color: #fff;
    }
    .el-col-5 {
      width: auto;
      background: #075aa7;
    }
  }
}
@media only screen and (max-width: 768px) {
  .menu {
    display: block;
  }
}
</style>