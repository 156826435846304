<template>
  <div class="footer">
    <div class="fot_nav">
      <!-- <div class="lists">帮助</div>
      <div class="lists">隐私</div>
      <div class="lists">条款</div> -->
    </div>
    <div class="fot_text">Copyright China Electronic Components Association. 中国电子元件行业协会 All rights reserved</div>
  </div>
</template>

<script>
export default {
  name: "footer",
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.footer {
  position:fixed;
  bottom: 0;
  text-align: center;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(58, 58, 58, 0.04);
  height: 55px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1; /* 添加这行代码，确保 Footer 在上层显示 */
}

.footer .fot_nav {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  margin-top: 12px;
}

.footer .fot_nav .lists {
  margin: 0 10px;
  color: rgba(0, 0, 0, 0.32);
  font-size: 10px;
  line-height: 145.523%; /* 14.552px */
}

.footer .fot_text {
  color: rgba(0, 0, 0, 0.32);
  font-size: 10px;
  line-height: 145.523%; /* 14.552px */
  text-align: center;
  margin-top: 2px;
}
</style>