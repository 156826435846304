<template>
  <div>
    <Header></Header>
    <headmenu></headmenu>
    <div class="content">
      <div class="lefttop">
        <img class="bg" src="../assets/indexbg11.png" alt="" />
        <div class="xmsb" @click="goto('/declare')">
          <img src="../assets/xmsb.png" alt="" />
          <span>项目申报</span>
        </div>
      </div>
      <!-- <div class="righttop">
        <div class="left">
          <img v-if="avatar" :src="avatar" alt="" style="border-radius: 50%" />
          <img v-else src="../assets/useravatop.png" alt="" style="border-radius: 50%" />
          <span class="text1">{{ userinfo.nickname }}</span>
          <span class="text2">ID:{{ userinfo.id }}</span>
        </div>
        <div class="right">
          <h3>{{ userinfo.company }}</h3>
          <div class="text1">
            <img src="../assets/info.png" alt="" />
            <span v-if="memberInfo"></span>
            <span v-else
              >公司成立于{{ memberInfo.create_time }}年，主要经营{{
                memberInfo.product_dis
              }}</span
            >
          </div>
          <div class="text2">
            <img src="../assets/local.png" alt="" />
            <span>{{ userinfo.province }} - {{ userinfo.city }}</span>
          </div>
          <div class="ckxq" @click="goto('/message')">查看详情</div>
        </div>
      </div> -->
      <div class="leftbottom">
        <div class="head">
          <div class="left">
            <img src="../assets/tzgg.png" alt="" />
            <span>通知公告</span>
          </div>
          <div class="right" @click="goto('/sysmessage')">
            查看全部<span>></span>
          </div>
        </div>
        <div class="article">
          <ul>
            <li
              v-for="(item, index) in noticelist"
              :key="index"
              @click="goto(`/sysdetail/${item.message_id}`)"
            >
              <div class="list">
                <!-- <img
                  v-if="item.is_see == 1"
                  src="../assets/dianno.png"
                  alt=""
                />
                <img
                  v-if="item.is_see == 0"
                  src="../assets/dianblue.png"
                  alt=""
                /> -->
                <img src="../assets/dianblue.png" alt="" />
                {{ item.message_title }}
              </div>
              <div class="thin">{{ item.createtime | dateFormat }}</div>
            </li>
            <!-- <li class="gray" v-for="(item, index) in noticelist" :key="index">
              <div class="list">
                <img src="../assets/dianyes.png" alt="" />
                {{ item.title }}
              </div>
              <div class="thin">{{ item.create_time | dateFormat }}</div>
            </li> -->
          </ul>
        </div>
      </div>
      <div class="rightbottom">
        <div class="box1" @click="goto('/service')">
          <img src="../assets/hyfw.png" alt="" />
          <h3>会员服务</h3>
          <h5>service</h5>
          <div class="jt">></div>
        </div>
        <div class="box2" @click="goapply">
          <img src="../assets/hyfw.png" alt="" />
          <h3>入会申请</h3>
          <h5>apply</h5>
          <div class="jt">></div>
        </div>
        <div class="box3" @click="goto('/guide')">
          <img src="../assets/hyfw.png" alt="" />
          <h3>入会指南</h3>
          <h5>entry guide</h5>
          <div class="jt">></div>
        </div>
        <div class="box4" @click="goto('/directory')">
          <img src="../assets/hyfw.png" alt="" />
          <h3>会员名录</h3>
          <h5>membership list</h5>
          <div class="jt">></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import headmenu from "../components/headmenu.vue";
import { getindexinfo, getEditMember } from "../api/index";
export default {
  data() {
    return {
      userinfo: {},
      noticelist: [],
      avatar: localStorage.getItem("avatar"),
      memberInfo: {},
    };
  },
  components: {
    Header,
    Footer,
    headmenu,
  },
  filters: {
    dateFormat(key) {
      key = key * 1000; //注意：key应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
      const dt = new Date(key);
      const y = dt.getFullYear();
      const m = (dt.getMonth() + 1 + "").padStart(2, "0");
      const d = (dt.getDate() + "").padStart(2, "0");
      //时间格式年月日、时分秒
      return `${y}-${m}-${d}`;
    },
  },
  created() {
    this.getindexinfo();
    if (localStorage.getItem("ismember") == 1) {
      this.getEditMember();
    }
  },
  methods: {
    //获取会员信息
    getEditMember() {
      getEditMember().then((res) => {
        this.memberInfo = res.data.data;
        // console.log('memberInfo',this.memberInfo)
      });
    },
    //路径跳转
    goto(url) {
      this.$router.push(url);
    },
    goapply() {
      if (localStorage.getItem("token")) {
        if (localStorage.getItem("ismember") == 1) {
          this.$message.success("您已入会");
        } else {
          this.$router.push("/apply");
        }
      } else {
        this.$message.error("请先登录");
        this.$router.push({ path: "/login", query: { islogin: 0 } });
      }
    },
    //获取首页信息
    getindexinfo() {
      this.Loading(".content");
      getindexinfo().then((res) => {
        this.isloading.close();
        // console.log("获取首页信息", res);
        if (res.data.code == 1) {
          this.noticelist = res.data.data.notice.slice(0, 7);
          this.userinfo = res.data.data.user;
          localStorage.setItem("company", res.data.data.user.company);
          // console.log('11111111111',res.data.data.notice.slice(0, 7))
          // console.log('111111',res.data.data.notice)
        } else {
          // setTimeout(res=>{
          //   this.goto('/login')
          // },500)
          // this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
body {
  // background-color: aqua;
  position: relative;
}

.content {
  width: 70%;
  margin: 68px auto 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .lefttop {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .bg {
      width: 35%;
      height: calc(100vw * 191 / 1920);
      width: calc(100vw * 250 / 1920);
      margin-left: 0;
    }

    .xmsb {
      cursor: pointer;
      height: calc(100vw * 65 / 1920);
      line-height: 65px;
      border-radius: 56px;
      width: 35%;
      background-color: #fff;
      text-align: center;
      margin-left: -150px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        padding-right: 5px;
        height: 23px;
        width: 22px;
      }

      span {
        color: #236ce4;
        font-size: calc(100vw * 22 / 1920);
        display: block;
      }
    }

    height: calc(100vw * 316 / 1920);
    background: url(../assets/indexbg1.png) no-repeat;
    background-size: 100% 100%;
  }

  .righttop {
    position: relative;
    width: 49%;
    height: calc(100vw * 316 / 1920);
    background: url(../assets/indexbg2.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 25%;
      margin: 10px;
      height: calc(100vw * 294 / 1920);
      background-color: #f2f9ff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        height: calc(100vw * 130 / 1920);
        width: calc(100vw * 130 / 1920);
        margin-bottom: 20px;
      }

      .text1 {
        font-size: calc(100vw * 22 / 1920);
        font-weight: bold;
        margin-bottom: 5px;
      }

      .text2 {
        display: flex;
        font-size: calc(100vw * 18 / 1920);
        color: #787878;
      }
    }

    .right {
      margin-top: -100px;
      margin-right: 80px;
      width: 55%;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: calc(100vw * 22 / 1920);
      }

      .text1 {
        display: flex;
        font-size: calc(100vw * 16 / 1920);
        margin-bottom: 15px;
        align-items: center;

        img {
          display: block;
          height: 18px;
          margin-right: 15px;
        }

        span {
          display: block;
        }
      }

      .text2 {
        display: flex;
        font-size: calc(100vw * 16 / 1920);
        align-items: center;

        img {
          height: 18px;
          margin-right: 15px;
          display: block;
        }

        span {
          display: block;
        }
      }

      .ckxq {
        cursor: pointer;
        font-size: calc(100vw * 14 / 1920);
        color: #6b6b6b;
        position: absolute;
        right: 50px;
        top: 20px;
      }
    }
  }

  .leftbottom {
    margin-top: 25px;
    border-radius: 9px;
    width: 49%;
    height: calc(100vw * 316 / 1920);
    background-color: #fff;

    .head {
      margin: 0 auto;
      width: 90%;
      display: flex;
      justify-content: space-between;
      border-bottom: 0.54px dashed #d9d9d9;
      align-items: center;
      height: 65px;

      .left {
        display: flex;
        align-items: center;
        font-size: calc(100vw * 20 / 1920);

        img {
          margin-right: 10px;
          height: 24px;
          width: 23px;
        }
      }

      .right {
        font-size: calc(100vw * 14 / 1920);
        color: #6b6b6b;
        cursor: pointer;
      }
    }

    .article {
      margin: 0 auto;
      width: 95%;
      margin-left: -10px;

      ul {
        list-style-type: none;

        li {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: calc(100vw * 18 / 1920);
          // font-weight: bold;
          padding-bottom: calc(100vw * 10 / 1920);

          .list {
          width: 80%;
            display: flex;
            align-items: center;

            img {
              padding-right: 10px;
              width: 7px;
              height: 7px;
            }
          }
        }

        .gray {
          width: 18%;
          color: #878787;
        }

        .thin {
          width: 18%;
          font-weight: normal;
        }
      }
    }
  }

  .rightbottom {
    margin-top: 25px;
    border-radius: 9px;
    width: 49%;
    height: calc(100vw * 316 / 1920);
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .box1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 23%;
      background-image: url(../assets/rbbg1.png);
      background-size: 100% 100%;
      color: #ffffff;

      h3 {
        font-size: calc(100vw * 22 / 1920);
        margin: 28px auto 0;
      }

      h5 {
        margin: 0 auto 28px;
        font-size: calc(100vw * 16 / 1920);
      }

      .jt {
        background-color: #fff;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        color: #fb8f8d;
        line-height: 35px;
        text-align: center;
      }
    }

    .box2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 23%;
      background-image: url(../assets/rbbg2.png);
      background-size: 100% 100%;
      color: #ffffff;

      h3 {
        font-size: calc(100vw * 22 / 1920);
        margin: 28px auto 0;
      }

      h5 {
        margin: 0 auto 28px;
        font-size: calc(100vw * 16 / 1920);
      }

      .jt {
        background-color: #fff;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        color: #5a8fe8;
        line-height: 35px;
        text-align: center;
      }
    }

    .box3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 23%;
      background-image: url(../assets/rbbg3.png);
      background-size: 100% 100%;
      color: #ffffff;

      h3 {
        font-size: calc(100vw * 22 / 1920);
        margin: 28px auto 0;
      }

      h5 {
        margin: 0 auto 28px;
        font-size: calc(100vw * 16 / 1920);
      }

      .jt {
        background-color: #fff;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        color: #f7bf3d;
        line-height: 35px;
        text-align: center;
      }
    }

    .box4 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 23%;
      background-image: url(../assets/rbbg4.png);
      background-size: 100% 100%;
      color: #ffffff;

      h3 {
        font-size: calc(100vw * 22 / 1920);
        margin: 28px auto 0;
      }

      h5 {
        margin: 0 auto 28px;
        font-size: calc(100vw * 16 / 1920);
      }

      .jt {
        background-color: #fff;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        color: #31cca6;
        line-height: 35px;
        text-align: center;
      }
    }
  }

  .el-button--primary {
    width: 334px;
    height: 50px;
  }

  .el-dialog__footer {
    margin-top: -30px;
    padding: 10px 20px 30px;
  }
}

@media only screen and (max-width: 1366px) {
  .content {
    width: 1000px;
    margin: 40px auto 40px;
  }

  .content .leftbottom .head {
    width: 90%;
    height: 45px;
  }

  .content .leftbottom {
    margin-top: 20px;
  }

  .content .rightbottom {
    margin-top: 20px;
  }

  .content .rightbottom h3 {
    margin: 10px auto 0;
  }

  .content .rightbottom .box1 h3 {
    margin: 10px auto 0;
  }

  .content .rightbottom .box2 h3 {
    margin: 10px auto 0;
  }

  .content .rightbottom .box3 h3 {
    margin: 10px auto 0;
  }

  .content .rightbottom .box4 h3 {
    margin: 10px auto 0;
  }

  .content .rightbottom .box1 h5 {
    margin: 0 auto 10px;
  }

  .content .rightbottom .box2 h5 {
    margin: 0 auto 10px;
  }

  .content .rightbottom .box3 h5 {
    margin: 0 auto 10px;
  }

  .content .rightbottom .box4 h5 {
    margin: 0 auto 10px;
  }

  .content .rightbottom .box1 .jt {
    height: 25px;
    width: 25px;
    line-height: 25px;
  }

  .content .rightbottom .box2 .jt {
    height: 25px;
    width: 25px;
    line-height: 25px;
  }

  .content .rightbottom .box3 .jt {
    height: 25px;
    width: 25px;
    line-height: 25px;
  }

  .content .rightbottom .box4 .jt {
    height: 25px;
    width: 25px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 1100px) {
  .content {
    width: 750px;
    margin: 20px auto 20px;
  }

  .content .righttop .right .text1 {
    font-size: 12px;
  }

  .content .righttop .left .text2 {
    font-size: 12px;
  }

  .content .righttop .right .ckxq {
    font-size: 12px;
  }

  .content .righttop .right {
    margin-top: 0px;
  }

  .content .rightbottom .box1 h3 {
    font-size: 12px;
  }

  .content .rightbottom .box1 h5 {
    margin: 0 auto 10px;
    font-size: 12px;
  }

  .content .rightbottom .box2 h3 {
    font-size: 12px;
  }

  .content .rightbottom .box2 h5 {
    margin: 0 auto 10px;
    font-size: 12px;
  }

  .content .rightbottom .box3 h3 {
    font-size: 12px;
  }

  .content .rightbottom .box3 h5 {
    margin: 0 auto 10px;
    font-size: 12px;
  }

  .content .rightbottom .box4 h3 {
    font-size: 12px;
  }

  .content .rightbottom .box4 h5 {
    margin: 0 auto 10px;
    font-size: 12px;
    text-align: center;
  }

  .content .leftbottom .article ul li {
    font-size: 12px;
    padding-bottom: 10px;
  }

  .content .leftbottom .article {
    margin: 0 auto;
    width: 95%;
    margin-left: 0px;
  }

  .content .leftbottom .article ul {
    list-style-type: none;
    padding-left: 20px;
  }

  .content .righttop .left .text1 {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .header .head .right .user {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    width: 100%;
    margin: 0px;
    padding: 0px 0px;
    margin-top: 70px;
  }

  .content .lefttop {
    width: 100%;
    border-radius: 10px;
    height: 150px;
    margin: 0 10px;
  }

  .content .lefttop .bg {
    width: auto;
    height: calc(100vw * 191 / 1920);
    width: calc(100vw * 250 / 1920);
    margin-left: 0px;
  }

  .content .lefttop .xmsb {
    height: 35px;
    line-height: 35px;
    border-radius: 50px;
    width: 150px;
    margin-left: 0px;
  }

  .content .lefttop .xmsb img {
    padding-right: 5px;
    height: 18px;
    width: 18px;
  }

  .content .righttop {
    width: 100%;
    background-size: cover;
    border-radius: 10px;
    height: 150px;
    margin: 0 10px;
    margin-top: 10px;
    position: static;
  }

  .content .righttop .right .ckxq {
    cursor: pointer;
    color: #6b6b6b;
    position: static;
    right: 50px;
    top: 20px;
    text-align: right;
  }

  .content .righttop .left {
    width: 120px;
    margin: 10px;
    height: 90%;
  }

  .content .righttop .right {
    margin-top: 0px;
    margin-right: 10px;
    width: 80%;
  }

  .content .righttop .right .text1 img {
    display: block;
    height: 16px;
    margin-right: 5px;
  }

  .content .lefttop .xmsb span {
    color: #236ce4;
    font-size: 12px;
    display: block;
  }

  .content .leftbottom {
    margin: 0 10px;
    margin-top: 10px;
    width: 100%;
    height: 200px;
  }

  .content .leftbottom .head {
    width: 97%;
    height: 45px;
  }

  .content .leftbottom .head .left img {
    margin-right: 5px;
    height: 15px;
    width: 15px;
  }

  .content .leftbottom .head .left {
    font-size: 12px;
  }

  .content .leftbottom .head .right {
    font-size: 12px;
    color: #6b6b6b;
    cursor: pointer;
  }

  .content .rightbottom {
    width: 100%;
    height: 150px;
    margin: 0 10px;
    margin-top: 10px;
  }

  .el-dialog--center {
    text-align: center;
    width: 90% !important;
  }

  .kefu[data-v-27e96f68] {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
  }
}
</style>