<template>
	<div class="box">
		<div class="sidebar">
			<sidebar></sidebar>
		</div>
		<div class="declare">
			<Header></Header>
			<div class="declare_cont">
				<div class="refer">
					<div class="img">
						<img src="../assets/wbanner_3.png" alt="">
					</div>
					<div class="title">提交成功</div>
					<div class="text">我们会尽快与您联系！</div>
				</div>
				<div class="cha"><img src="../assets/wicon_4.png" alt=""></div>
			</div>
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
	import Header from "../components/header2.vue";
	import Footer from "../components/footer2.vue";
	import sidebar from "../components/sidebar.vue";
	export default {
		components: {
			Header,
			Footer,
			sidebar
		},
	};
</script>

<style scoped>
	body {
		padding: 0;
		margin: 0;
	}

	.box {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap
	}

	.sidebar {
		width: 18%;
	}

	.declare {
		width: 82%;
		background: linear-gradient(180deg, rgba(171, 198, 246, 0.27) 0%, rgba(216, 224, 232, 0.10) 100%);
	}

	.declare_cont {
		height: 62%;
		border-radius: 5px;
		background: #FFF;
		margin: 35px 72px 91px 82px;
		padding: 47px 101px 67px 101px;
		position: relative;
	}

	.declare_cont .refer {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.declare_cont .refer .img {
		margin-bottom: 30px;
	}

	.declare_cont .refer .title {
		margin-bottom: 5px;
		color: #1890FF;
		text-align: center;
		font-size: 26px;
		font-weight: 500;
	}

	.declare_cont .refer .text {
		color: #434343;
		text-align: center;
		font-size: 18px;
	}

	.declare_cont .cha {
		position: absolute;
		top: -15px;
		right: -35px;
	}

	@media only screen and (max-width:1600px) {
		.declare_cont {
			height: 50%;
			margin: 30px 30px 0;
		}

		.declare_cont .cha {
			position: absolute;
			top: -15px;
			right: -15px;
		}
	}

	@media only screen and (max-width:768px) {
		.sidebar {
			display: none;
		}

		.declare {
			width: 100%;
			background: linear-gradient(180deg, rgba(171, 198, 246, 0.27) 0%, rgba(216, 224, 232, 0.10) 100%);
		}

		.declare_cont {
			height: 50%;
			margin: 60px 30px 0;
		}
	}
</style>