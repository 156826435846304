<template>
  <div class="messages">
    <el-container>
      <el-aside class="sidebar">
        <sidebar></sidebar>
      </el-aside>
      <el-container>
        <Header></Header>
        <!-- <el-header><Header></Header></el-header> -->
        <el-main>
          <div class="message">
            <div class="item" @click="witch(3)">
              <!-- <el-badge :value="200"> -->
              <img src="../assets/xxzx.png" alt="" />
              <!-- </el-badge> -->

              <span :class="type == 3 ? 'item_active' : ''">系统通知</span>
            </div>
            <div class="item" @click="witch(1)">
              <!-- <el-badge :value="200"> -->
              <img src="../assets/jfxx.png" alt="" />
              <!-- </el-badge> -->

              <span :class="type == 1 ? 'item_active' : ''">缴费信息</span>
            </div>
            <div class="item" @click="witch(2)">
              <el-badge :value="count">
                <img src="../assets/sh.png" alt="" />
              </el-badge>
              <span :class="type == 2 ? 'item_active' : ''">审核</span>
            </div>
          </div>
          <div class="lists">
            <div class="list" v-if="listinfo.data.length > 0">
              <ul>
                <li
                  v-for="(item, index) in listinfo.data"
                  :key="index"
                  @click="
                    goto(
                      `/messagedetail?id=${
                        type == 3 ? item.message_id : item.id
                      }&type=${type}`
                    )
                  "
                >
                  <div class="list">
                    <img
                      v-if="item.is_see == 1"
                      src="../assets/dianno.png"
                      alt=""
                    />
                    <img
                      v-if="item.is_see == 0"
                      src="../assets/dianblue.png"
                      alt=""
                    />
                    {{
                      type == 3 ? item.messagenotice.message_title : item.title
                    }}
                  </div>
                  <div class="thin">
                    {{
                      type == 3
                        ? item.createtime
                        : item.create_time | dateFormat
                    }}
                  </div>
                </li>
              </ul>
            </div>
            <div v-else style="text-align: center; padding: 100px">
              没有更多了
            </div>
            <el-pagination
              background
              layout="prev, pager, next"
              :current-page="listinfo.current_page"
              @current-change="currentchange"
              :total="listinfo.total"
            >
            </el-pagination>
          </div>
        </el-main>
        <el-footer>
          <Footer></Footer>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { message } from "../api/index";
import Header from "../components/header2.vue";
import Footer from "../components/footer.vue";
import sidebar from "../components/sidebar.vue";
export default {
  data() {
    return {
      type: 2,
      limit: 8,
      page: 1,
      listinfo: {},
      count: 0,
    };
  },
  components: {
    Header,
    Footer,
    sidebar,
  },
  mounted() {
    // if(localStorage.getItem('token')){
    this.message();
    // }else{
    //   this.$router.push('/login');
    // }
  },
  filters: {
    dateFormat(key) {
      key = key * 1000; // 注意：key应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
      const dt = new Date(key);
      const y = dt.getFullYear();
      const m = (dt.getMonth() + 1 + "").padStart(2, "0");
      const d = (dt.getDate() + "").padStart(2, "0");
      // 时间格式年月日、时分秒
      return `${y}-${m}-${d}`;
    },
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
    witch(type) {
      this.type = type;
      this.message();
    },
    message() {
      this.Loading(".lists");
      message({
        limit: this.limit,
        page: this.page,
        type: this.type,
      }).then((res) => {
        this.isloading.close();
        if (res.data.code == 1) {
          // console.log('88888888',res)
          this.listinfo = res.data.data.list;
          this.count = res.data.data.count;
        } else {
          this.$message.error(res.data.msg);
          if (res.data.code == 401) {
            window.localStorage.clear();
            this.$router.push("/login");
          }
        }
      });
    },
    currentchange(page) {
      // console.log(page)
      this.page = page;
      this.limit = 8;
      this.message();
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  padding: 0;
  margin: 0;
}

.el-footer {
  margin: 0;
  padding: 0;
  height: 50px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  margin: 0;
  padding: 0;
  // width:18%
}

.el-main {
  // border: 1px solid red;
  background: linear-gradient(to bottom, #abc6f6, #ffffff) no-repeat;
  background-size: 100% 100%;
  color: #333;
  text-align: center;
  // min-height: 100%;
  margin-top: 0px;

  //   display: flex;
  //   flex-direction: column;
  .message {
    margin: 40px auto 30px;
    display: flex;
    justify-content: space-around;
    width: 50%;
    font-size: 18px;

    .item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        margin-top: 10px;
      }

      .item_active {
        color: #1890ff;
      }
    }
  }

  .lists {
    height: 530px;
    background-color: #fff;
    width: 90%;
    margin-left: -10px;
    border-radius: 5px;
    margin: 0 auto;

    /deep/.el-pagination.is-background .el-pager li {
      background-color: #fff;
      color: #898989;
      border: 1px solid #d9d9d9;
      font-family: PingFang SC;
      font-weight: 400;
      height: 30px;
    }

    /deep/.el-pagination.is-background .btn-next,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .el-pager li {
      background-color: #fff;
      color: #898989;
      border: 1px solid #d9d9d9;
      font-family: PingFang SC;
      font-weight: 400;
      height: 30px;
    }

    /deep/.el-pagination.is-background .btn-prev:disabled {
      background-color: #fff;
      color: #898989;
      border: 1px solid #d9d9d9;
      font-family: PingFang SC;
      font-weight: 400;
      height: 30px;
    }

    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      border: 1px solid #1890ff;
      background-color: #fff;
      color: #1890ff;
      height: 30px;
    }

    /deep/.el-pagination.is-background .btn-prev {
      background-color: #fff;
      color: #898989;
      border: 1px solid #d9d9d9;
      font-family: PingFang SC;
      font-weight: 400;
      height: 30px;
    }

    .list {
      ul {
        list-style-type: none;
        width: 90%;
        margin: 0 auto;
        padding: 30px 0;

        li {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 18px;
          font-weight: bold;
          // padding-bottom: 10px;
          border-bottom: 0.5px solid #c1c1c1;
          line-height: 50px;
          height: 50px;

          .list {
            display: flex;
            align-items: center;

            img {
              padding-right: 10px;
              width: 7px;
              height: 7px;
            }
          }
        }

        .gray {
          color: #878787;
        }

        .thin {
          font-weight: normal;
        }
      }
    }
  }
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

@media only screen and (max-width: 768px) {
  .messages {
    .sidebar {
      width: 0;
      display: none;
    }

    .message {
      width: 100%;

      .item {
        img {
          width: 15vw;
        }

        span {
          font-size: 14px;
        }
      }
    }

    .lists {
      height: auto;
      padding-bottom: 20px;

      .list {
        font-size: 12px;
      }

      .thin {
        font-size: 12px;
      }
    }
  }

  .el-main {
    background: linear-gradient(to bottom, #abc6f6, #ffffff) no-repeat;
    background-size: 100% 100%;
    color: #333;
    text-align: center;
    margin-top: 0px;
    padding: 10px;
  }

  .el-main .message {
    margin: 10px auto 10px;
    display: flex;
    justify-content: space-around;
    width: 80%;
    font-size: 18px;
  }
}
</style>