import Vue from "vue";
import VueRouter from 'vue-router'
import index from "../pages/index.vue";
import service from "../pages/service.vue";
import sysmessage from "../pages/sysmessage.vue";
import sysdetail from "../pages/sysdetail.vue";
import servicedetails from "../pages/servicedetails.vue";
import apply from "../pages/apply.vue";
import success from "../pages/success.vue";
import guide from "../pages/guide.vue";
import directory from "../pages/directory.vue";
import login from "../pages/login.vue";
import register from "../pages/register.vue";
import message from "../pages/message.vue";
import messagedetail from "../pages/messagedetail.vue";
import editapply from "../pages/editapply.vue";
import certificate from "../pages/certificate.vue";
import declare from "../pages/declare.vue";
import message2 from "../pages/message2.vue";
import password from "../pages/password.vue";
import pay_list from "../pages/pay_list.vue";
import pay from "../pages/pay.vue";
import quit from "../pages/quit.vue";
import statistics from "../pages/statistics.vue";
import submit from "../pages/submit.vue";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: "/",
      redirect: "/index"
    },
    {
      path: "/index",
      name: 'index',
      component: index,
    },
    {
      path: "/service",
      name: 'service',
      component: service,
    }
    ,
    {
      path: "/sysmessage",
      name: 'sysmessage',
      component: sysmessage,
    }
    ,
    {
      path: "/sysdetail/:id",
      name: 'sysdetail',
      component: sysdetail,
    }
    ,
    {
      path: "/servicedetails/:id",
      name: 'servicedetails',
      component: servicedetails,
    }
    ,
    {
      path: "/apply",
      name: 'apply',
      component: apply,
    }
    ,
    {
      path: "/success",
      name: 'success',
      component: success,
    }
    ,
    {
      path: "/guide",
      name: 'guide',
      component: guide,
    }
    ,
    {
      path: "/directory",
      name: 'directory',
      component: directory,
    }
    ,
    {
      path: "/login",
      name: 'login',
      component: login,
    }
    ,
    {
      path: "/register",
      name: 'register',
      component: register,
    }
    ,
    {
      path: "/message",
      name: 'message',
      component: message,
    }
    ,
    {
      path: "/messagedetail",
      name: 'messagedetail',
      component: messagedetail,
      props: true
    }
    ,
    {
      path: "/editapply",
      name: 'editapply',
      component: editapply,
    }
    ,
    {
      path: "/certificate",
      name: 'certificate',
      component: certificate,
    }
    ,
    {
      path: "/declare",
      name: 'declare',
      component: declare,
    }
    ,
    {
      path: "/message2",
      name: 'message2',
      component: message2,
    }
    ,
    {
      path: "/password",
      name: 'password',
      component: password,
    }
    ,
    {
      path: "/pay_list",
      name: 'pay_list',
      component: pay_list,
    }
    ,
    {
      path: "/pay",
      name: 'pay',
      component: pay,
    }
    ,
    {
      path: "/quit",
      name: 'quit',
      component: quit,
    }
    ,
    {
      path: "/statistics/:id",
      name: 'statistics',
      component: statistics,
      props: true
    }
    ,
    {
      path: "/submit",
      name: 'submit',
      component: submit,
    }
  ]
})
export default router

