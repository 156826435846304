<template>
  <div class="messages">
    <el-container>
      <el-aside width="18%"><sidebar></sidebar></el-aside>
      <el-container>
        <Header></Header>
        <el-main>
          <div class="detail">
            <div class="head">
              <span>{{ type==3?info.message_title:info.title }}</span>
              <span v-if="type==3">{{ info.create_time_text}}</span>
              <span v-else>{{info.create_time | dateFormat}}</span>
            </div>
            <div class="text">
              {{type==3?info.message_content:info.content}}
            </div>
          </div>
        </el-main>
        <el-footer><Footer></Footer></el-footer>
      </el-container>
    </el-container>
  </div>
</template>
  
  <script>
  import { messageDetails } from "../api/index";
import Header from "../components/header2.vue";
import Footer from "../components/footer2.vue";
import sidebar from "../components/sidebar.vue";
export default {
  data() {
    return {
      type: 1,
      info:{}
    };
  },
  // props: ["id"],
  components: {
    Header,
    Footer,
    sidebar,
  },
  filters: {
    dateFormat(key) {
      key = key * 1000; // 注意：key应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
      const dt = new Date(key);
      const y = dt.getFullYear();
      const m = (dt.getMonth() + 1 + "").padStart(2, "0");
      const d = (dt.getDate() + "").padStart(2, "0");
      // 时间格式年月日、时分秒
      return `${y}-${m}-${d}`;
    },
  },
  mounted(){
    this.type=this.$route.query.type
      this.messageDetails()
      // console.log('gsw',this.$route)
  },
  methods:{
    messageDetails(){
      // console.log(this.id)
      this.Loading(".detail");
      messageDetails({
        id:this.$route.query.id,
        type:this.$route.query.type
      }).then(res=>{
        this.isloading.close();
        if(res.data.code==1){
          this.info=res.data.data
        }else{
          this.$message.error(res.data.msg);
        }
      })
    },
    
  }
};
</script>
  
  <style lang="scss" scoped>
body {
  padding: 0;
  margin: 0;
}
.el-header {
  margin: 0;
  padding: 0;
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  height: 118px;
  line-height: 118px;
}
.el-footer {
  margin: 0;
  padding: 0;
  height: 50px;
}
.el-aside {
  // background-color: #d3dce6;
  color: #333;
  text-align: center;
  min-height: 100vh;
}

.el-main {
  background: linear-gradient(to bottom, rgba(171, 198, 246, 0.27), #ffffff)
    no-repeat;
  background-size: 100% 100%;
  color: #333;
  text-align: center;
  margin-top: 60px;
  // min-height: 80vh;
  .detail {
    background-color: #ffffff;
    height: auto;
    width: 90%;
    margin: 50px auto;
    padding-bottom: 20px;
    .head {
      height: 90px;
      border-bottom: 0.5px solid #c1c1c1;
      width: 93%;
      margin: 0 auto;
      line-height: 90px;
      display: flex;
      justify-content: space-between;
      span:first-child {
        font-size: 26px;
      }
      span:last-child {
        font-size: 18px;
        color: #636363;
      }
    }
    .text {
      width: 93%;
      margin: 20px auto;
      font-size: 18px;
      line-height: 30px;
      text-align: left;
    }
  }
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
@media only screen and (max-width: 768px){
	.el-main {
	    background: linear-gradient(to bottom, rgba(171, 198, 246, 0.27), #ffffff) no-repeat;
	    background-size: 100% 100%;
	    color: #333;
	    text-align: center;
	    margin-top: 10px;
	    padding: 10px;
	}
	.el-main .detail .head span[data-v-eb684178]:last-child {
	    font-size: 16px;
	    color: #636363;
	}
}
</style>