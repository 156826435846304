<template>
  <div class="header">
    <div class="head">
      <div class="left">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="center">
        <ul>
          <li
            :class="$route.name == 'index' ? 'active' : ''"
            @click="goto('/index')"
          >
            会员首页
          </li>
          <li
            :class="$route.name == 'service' ? 'active' : ''"
            @click="goto('/service')"
          >
            会员服务
          </li>
          <li :class="$route.name == 'apply' ? 'active' : ''" @click="goapply">
            入会申请
          </li>
          <li
            :class="$route.name == 'guide' ? 'active' : ''"
            @click="goto('/guide')"
          >
            入会指南
          </li>
          <li
            :class="$route.name == 'directory' ? 'active' : ''"
            @click="goto('/directory')"
          >
            会员名录
          </li>
        </ul>
      </div>
      <div class="right">
        <div class="wxlogo" @mouseover="mouseover" @mouseleave="mouseleave">
          <img class="sharewx" src="../assets/wx.png" alt="" />
          <div class="wximg" v-show="yc">
            <img class="ewmborder" src="../assets/ewmborder.png" alt="" />
            <img class="ewm" src="../assets/weixinewm.jpg" alt="" />
          </div>
        </div>
        <div class="wxlogo" @mouseover="mouseover2" @mouseleave="mouseleave2">
          <img class="sharewx" src="../assets/weibo.png" alt="" />
          <div class="wximg" v-show="yc2">
            <img class="ewmborder" src="../assets/ewmborder.png" alt="" />
            <img class="ewm" src="../assets/weiboewm.png" alt="" />
          </div>
        </div>
        <div
          class="user"
          @click.stop="gomessage"
          @mouseover="mouseover3"
          @mouseleave="mouseleave3"
        >
          <!-- <img
            class="ava"
            :src="avatar"
            alt=""
            style="border-radius: 50%"
            v-if="avatar"
          />
          <img
            class="ava"
            src="../assets/useravatop.png"
            alt=""
            style="border-radius: 50%"
            v-else
          /> -->
          <span style="width: 100%;">{{ username || "未登录" }}</span>
          <div class="logout" v-show="yc3 && username">
            <div class="botton" @click.stop="logout">退出</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "../api/index";
export default {
  name: "header",
  props: {},
  data() {
    return {
      yc: false,
      yc2: false,
      yc3: false,
      username: localStorage.getItem("username"),
      avatar: localStorage.getItem("avatar"),
    };
  },
  props: [],
  mounted() {
    // console.log(this.$route)
  },
  methods: {
    goapply() {
      if (localStorage.getItem("token")) {
        if (localStorage.getItem("ismember") == 1) {
          this.$message.success("您已入会");
        } else {
          this.$router.push("/apply");
        }
      } else {
        this.$message.error("请先登录");
        this.$router.push({ path: "/login", query: { islogin: 0 } });
      }
    },
    gomessage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/message");
      } else {
        this.$message.error("请先登录");
        this.$router.push({ path: "/login" });
      }
    },
    logout() {
      let that = this;
      that.Loading(".user");
      logout().then((res) => {
        that.isloading.close();
        if (res.data.code == 1) {
          window.localStorage.clear();
          that.$message.success(res.data.msg);
          that.goto("/login");
        } else {
          // that.$message.error(res.data.msg);
        }
      });
    },
    goto(url) {
      this.$router.push(url);
    },
    mouseover() {
      this.yc = true;
    },
    mouseleave() {
      this.yc = false;
    },
    mouseover2() {
      this.yc2 = true;
    },
    mouseleave2() {
      this.yc2 = false;
    },
    mouseover3() {
      this.yc3 = true;
    },
    mouseleave3() {
      this.yc3 = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 118px;
  // line-height: 118px;
  background-color: #fff;

  .head {
    // border: 1px solid red;
    height: 118px;
    display: flex;
    width: 88%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

    .left {
    cursor: pointer;
      // border: 1px solid red;
      width: 40%;

      img {
        width: 100%;
      }
    }

    .center {
      // border: 1px solid red;
      width: 35%;

      ul {
        // border: 1px solid red;
        list-style: none;
        display: flex;
        justify-content: space-between;

        li {
          font-size: 18px;
          cursor: pointer;
        }

        .active {
          color: #04408a;
          font-weight: bold;
        }
      }
    }

    .right {
      // border: 1px solid red;
      width: 15%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ava {
        margin-left: 15px;
        height: 47px;
        width: 47px;
      }

      .wxlogo {
        z-index: 999;
        position: relative;
        margin-right: 15px;

        .wximg {
          position: absolute;
          left: -40px;

          .ewm {
            position: absolute;
            top: 21px;
            left: 11px;
            width: 110px;
            height: 110px;
          }
        }

        .sharewx {
          width: 33px;
          height: 33px;
        }
      }

      .user {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;

        img {
          height: 50px;
          width: 50px;
          margin-right: 10px;
        }

        .logout {
          position: absolute;
          background: #fff;
          height: 50px;
          width: 80px;
          top: 100%;
          right: -15%;
          border-radius: 10px;
          border: 1px solid #236ce4;

          .botton {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 20px;
            width: 40px;
            border-radius: 5px;
            background: #409eff;
            color: #fff;
            font-size: 14px;
            text-align: center;
            padding: 2px 4px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .header {
    height: 90px;
  }

  .header .head {
    height: 90px;
    width: 1000px;
  }

  .header .head .left {
    width: 30%;
    cursor: pointer;
  }
  .header .head .center{
    width: 35%;
  }
  .header .head .right{
    width: 20%;
  }
  .header .head .center ul li {
    font-size: 14px;
    cursor: pointer;
  }

  .header .head .right .wxlogo .sharewx {
    width: 23px;
    height: 23px;
  }

  .header .head .right .wxlogo .wximg {
    position: absolute;
    left: -52px;
  }

  .header .head .right .user img {
    height: 35px;
    width: 35px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1100px) {
  .header .head {
    height: 90px;
    width: 750px;
  }

  .header .head .center ul {
    padding-left: 10px;
  }

  .header .head .left {
    width: 25%;
  }

  .header .head .center ul li {
    font-size: 12px;
    cursor: pointer;
  }

  .header .head .right {
    width: 28%;
  }

  .header .head .right .user img {
    height: 35px;
    width: 35px;
    margin-right: 5px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    display: none;
  }
}
</style>