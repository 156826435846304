import Vue from 'vue'
import Vuex from 'vuex'
import vuexpersistedstate from 'vuex-persistedstate'
Vue.use(Vuex)

// 创建仓库 store
const store = new Vuex.Store({
  state: {
    infoString: {}
  },
  mutations: {
    // 方法里参数 第一个参数是当前store的state属性
    // payload 载荷 运输参数 调用mutaiions的时候 可以传递参数 传递载荷
    addInfo(state, info) {
      state.infoString = info
      console.log('sotre', info)
      // localStorage.setItem('info',info)
    }
  },
  plugins: [
    vuexpersistedstate({
      key: 'info'
    })
  ]
})
// 导出仓库
export default store