<!-- 登录 -->
<template>
	<div>
		<Header></Header>
		<headmenu></headmenu>
		<div class="bei">
			<!-- 登录 -->
			<div class="login">
				<div class="login_text">
					<div @click="type = 1" class="login11" v-if="islogin !=0">
						<h4 :class="type == 1 ? 'blue' : 'black'">会员登录</h4>
						<div :class="type == 1 ? 'line' : 'noline'"></div>
					</div>
					<div @click="type = 2" class="login11" >
						<h4 :class="type == 2 ? 'blue' : 'black'">非会员登录</h4>
						<div :class="type == 2 ? 'line' : 'noline'"></div>
					</div>
				</div>

				<!-- 账号密码 -->
				<div v-show="type == 1 && islogin !=0">
					<div class="input_com">
						<el-input class="item" v-model="account" placeholder="请输入账号">
						</el-input>
						<el-input class="item" v-model="password" type="password" placeholder="请输入密码">
						</el-input>
					</div>
				</div>

				<!-- 手机号 -->
				<div v-show="type == 2 || islogin ==0">
					<div class="input_com">
						<el-input class="item" v-model="mobile" placeholder="请输入手机号">
							<template slot="prepend">
								<el-select v-model="qvhao" placeholder="86+" style="width: 85px; outline: none"
									size="large">
									<el-option label="87+" :value="1" />
									<el-option label="89+" :value="2" />
									<el-option label="85+" :value="3" />
								</el-select>
							</template>
						</el-input>
						<el-input v-model="captcha" placeholder="请输入验证码">
							<template slot="suffix">
								<div style="
                    width: 200px;
                    height: 58px;
                    text-align: center;
                    color: #1890ff;
                    line-height: 58px;
                    font-size: calc(100vw * 20 / 1920);
                    background: #fff;
                    border-left: 1px solid #ccc;
                    border-top: 1px solid #ccc;
                  " @click="sendcode">
									{{ getCodeText }}
								</div>
							</template>
						</el-input>
					</div>
				</div>

				<div style="
            display: flex;
            align-items: center;
            margin-left: 11.35%;
            margin-top: 2%;
            margin-bottom: 10px;
          ">
					<div style="
              margin-right: 1%;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
            ">
						<input type="checkbox" style="margin: auto" />
					</div>
					<div style="
              display: flex;
              justify-content: space-between;
              width: 85%;
              cursor: pointer;
            ">
						<div style="font-size: 14px">自动登录</div>
						<div v-if="type == 1" style="font-size: 14px; color: #1890ff" @click="gowjmm">
							忘记密码
						</div>
					</div>
				</div>
				<button v-if="type == 1 && islogin !=0" class="login_but" @click="loGin1">登录</button>
				<button v-if="type == 2 || islogin ==0" class="login_but" @click="loGin2">登录</button>

			</div>
		</div>
		<Footer></Footer>
	</div>
</template>
<script>
	import Header from "../components/header.vue";
	import Footer from "../components/footer.vue";
	import headmenu from "../components/headmenu.vue";
	import {
		login,
		sendcode,
		mobilelogin
	} from "../api";
	export default {
		data() {
			return {
				type: 1,
				account: "",
				password: "",
				mobile: "",
				captcha: "",
				qvhao: "",
				getCodeText: "点击发送验证码",
				codeFlag: true,
				countdown: 60,
				getCodeisWaiting: false,
				islogin:0
			};
		},
		components: {
			Header,
			Footer,
			headmenu
		},
		mounted(){
			// console.log(this.$route.query.islogin)
			this.islogin=this.$route.query.islogin
		},
		methods: {
			//账号密码登录
			loGin1() {
				let that = this;
				that.Loading(".bei");
				login({
					account: that.account,
					password: that.password,
				}).then((res) => {
					that.isloading.close();
					// console.log("登录", res);
					if (res.data.code == 1) {
						that.$message.success(res.data.msg);
						localStorage.setItem("uid", res.data.data.userinfo.id);
						localStorage.setItem("token", res.data.data.userinfo.token);
						localStorage.setItem("username", res.data.data.userinfo.username);
						localStorage.setItem("avatar", res.data.data.userinfo.avatar);
						localStorage.setItem("ismember", res.data.data.userinfo.is_member);
						setTimeout(() => {
							that.goto("/index");
						}, 500);
					} else {
						that.$message.error(res.data.msg);
					}
				});
			},
			//验证码登录
			loGin2() {
				let that = this;
				that.Loading(".bei");
				mobilelogin({
					mobile: that.mobile,
					captcha: that.captcha,
					event: "mobilelogin",
				}).then((res) => {
					that.isloading.close();
					// console.log("登录", res);
					if (res.data.code == 1) {
						that.$message.success(res.data.msg);
						localStorage.setItem("uid", res.data.data.userinfo.id);
						localStorage.setItem("token", res.data.data.userinfo.token);
						localStorage.setItem("username", res.data.data.userinfo.username);
						localStorage.setItem("avatar", res.data.data.userinfo.avatar);
						localStorage.setItem("ismember", res.data.data.userinfo.is_member);
						setTimeout(() => {
							that.goto("/index");
						}, 500);
					} else {
						that.$message.error(res.data.msg);
					}
				});
			},
			goto(url) {
				this.$router.push(url);
			},
			gowjmm(){
				this.type = 2
				this.$message.error('请手机号登录，自行修改或联系管理员修改。');
			},
			message() {},
			sendcode() {
				let that = this;
				if (that.codeFlag) {
					that.getCodeText = "发送中..."; //XX秒后重新发送
					that.codeFlag = false;
					sendcode({
						mobile: that.mobile,
						event: "mobilelogin",
					}).then((res) => {
						if (res.data.code == 1) {
							that.$message.success(res.data.msg);
							that.getCodeText = that.countdown + "秒后重新发送";
							that.getCodeisWaiting = true;
							setTimeout(() => {
								that.setTimer(); //调用定时器方法
								console.log('调用定时器')
							}, 1000);
						} else {
							that.$message.error(res.data.msg);
							that.codeFlag = true;
							that.getCodeText = "点击获取验证码";
						}
					});
				}
			},
			setTimer() {
				let holdTime = 60; //定义变量并赋值
				this.getCodeText = "重新获取(60)";
				let Timer = setInterval(() => {
					if (holdTime <= 0) {
						this.getCodeisWaiting = false;
						this.getCodeText = "点击获取验证码";
						this.codeFlag = true;
						this.clearInterval(Timer); //清除该函数
						return; //返回前面
					}
					this.getCodeText = "重新获取(" + holdTime + ")";
					holdTime--;
				}, 1000);
			},
		},
	};
</script>

<style lang='scss'>
	input:focus {
		box-shadow: 0px 0px 10px 0px rgba(127, 127, 127, 0.1);
		border-color: #ffad01;
	}

	.login_img_deng {
		width: 166px;
		height: 55px;
		background: #ffffff;
		border-radius: 12px;
		opacity: 1;
		// text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		// line-height: 55px;
	}

	.bei {
		// height: 960px;
		height: 100%;
		// background: url(../image/imagess/Group2.png) no-repeat 100% 100%;
		background-size: contain;
		overflow: hidden;

		/* 登录 */
		.login {
			width: 37.23%;
			// height: 17.81%;
			background: #ffffff;
			// box-shadow: 0px 0px 4px 0px rgba(108, 40, 253, 0.24);
			border-radius: 20px 20px 20px 20px;
			opacity: 1;
			border: 1px solid #ffffff;
			margin: 0 32.5% 10.6% 30.26%;
			margin-top: 5.77%;

			.input_com {
				width: 80%;
				margin: 0 auto;

				.item {
					margin-bottom: 15px !important;
				}

				.el-input__inner {
					width: 100%;
					height: 60px !important;
					outline: none;
					font-size: 20px;
				}

				.el-input-group__prepend .el-input {
					height: 60px !important;
				}
			}

			.login_text {
				height: 36px;
				font-size: 20px;
				line-height: 35px;
				text-align: center;
				margin: 30px auto 40px !important;
				display: flex;
				justify-content: center;
				width: 70%;

				.login11 {
					width: 40%;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;

					.blue {
						color: #1890ff;
						// font-size: 20px;
						font-weight: 400;
					}

					.black {
						color: #000;
						font-weight: 400;
					}

					.noline {
						margin-top: -23px;
						margin-bottom: 15px;
					}

					.line {
						margin-top: -23px;
						margin-bottom: 15px;
						width: 60% !important;
						text-align: center;
						border-bottom: 3px solid #1890ff;
					}

					.noline {
						margin-top: -23px;
						margin-bottom: 15px;
					}
				}
			}

			.login_but {
				width: 77.34%;
				height: 64px;
				background: #1890ff;
				border-radius: 5px 5px 5px 5px;
				opacity: 1;
				font-size: calc(100vw * 20 / 1920);
				font-weight: 400;
				color: #ffffff;
				line-height: 23px;
				margin: 0 11.1% 3.07%;
				border: 0;
			}
		}
	}

	@media only screen and (max-width: 1366px) {
		.bei .login {
			width: 500px;
			background: #ffffff;
			border-radius: 20px 20px 20px 20px;
			opacity: 1;
			border: 1px solid #ffffff;
			margin: 0 auto;
			margin-top: 10%;
		}

		.bei .login .login_but {
			width: 80%;
			height: 50px;
			background: #1890ff;
			border-radius: 5px 5px 5px 5px;
			opacity: 1;
			font-size: calc(100vw * 20 / 1920);
			font-weight: 400;
			color: #ffffff;
			line-height: 23px;
			margin: 0 10% 5%;
			border: 0;
		}

		.bei .login .login_but {
			font-size: 14px;
		}
	}

	@media only screen and (max-width: 768px) {
		.bei {
			height: 70vh;
			margin-top: 70px;
		}

		.bei .login .login_text {
			height: 36px;
			font-size: 16px;
			line-height: 35px;
			text-align: center;
			margin: 10px auto 15px !important;
			display: flex;
			justify-content: center;
			width: 80%;
		}

		.bei .login .input_com .el-input__inner {
			width: 100%;
			height: 40px !important;
			outline: none;
			font-size: 14px;
		}

		.bei .login .login_but {
			width: 80%;
			height: 40px;
			background: #1890ff;
			border-radius: 5px 5px 5px 5px;
			opacity: 1;
			font-size: 14px;
			font-weight: 400;
			color: #ffffff;
			line-height: 23px;
			margin: 0 10% 5%;
			border: 0;
		}

		.bei .login {
			width: 350px;
			background: #ffffff;
			border-radius: 10px;
			opacity: 1;
			border: 1px solid #ffffff;
			margin: 0 auto;
			margin-top: 10%;
		}

		.bei .login .input_com .el-input-group__prepend .el-input {
			height: 40px !important;
		}

		.el-input__suffix-inner div {
			width: 100px !important;
			height: 38px !important;
			line-height: 38px !important;
			font-size: 12px !important;
		}
	}
</style>