<template>
  <div>
    <Header></Header>
	<headmenu></headmenu>
    <div class="apply">
      <div class="title">
        <h1>入会申请</h1>
        <div class="line"></div>
      </div>
      <Form></Form>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Form from "../components/form.vue";
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import headmenu from "../components/headmenu.vue";
export default {
  name: "apply",
  components: {
    Form,
    Header,
    Footer,
	headmenu
  },
};
</script>

<style lang="scss" scoped>
.apply {
  width: 65%;
  margin: 0 auto 60px;
  .title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 32px;
    }
    .line {
      margin-top: -5px;
      width: 50px;
      text-align: center;
      border-bottom: 4px solid #1890ff;
    }
  }
}
</style>