<template>
  <div class="head_wap">
    <div class="head">
      <div class="logo">
        <img src="../assets/logo.png" alt="" />
      </div>
      <div class="menu">
        <div class="wap_bg" v-if="isshow" @click="isshow = !isshow"></div>
        <img
          src="../assets/banner_134.png"
          class="imgs"
          @click="isshow = !isshow"
          alt=""
        />
        <div class="list_cont" v-if="isshow">
          <div class="center">
            <ul>
				<li
                :class="$route.name == 'index' ? 'active' : ''"
                @click.stop="gomessage"
              >
                我的
              </li>
              <li
                :class="$route.name == 'index' ? 'active' : ''"
                @click.stop="goto('/index')"
              >
                会员首页
              </li>
              <li
                :class="$route.name == 'service' ? 'active' : ''"
                @click.stop="goto('/service')"
              >
                会员服务
              </li>
              <li
                :class="$route.name == 'apply' ? 'active' : ''"
                @click.stop="goapply"
              >
                入会申请
              </li>
              <li
                :class="$route.name == 'guide' ? 'active' : ''"
                @click.stop="goto('/guide')"
              >
                入会指南
              </li>
              <li
                :class="$route.name == 'directory' ? 'active' : ''"
                @click.stop="goto('/directory')"
              >
                会员名录
              </li>
            </ul>
          </div>
          <div class="right">
            <div
              class="user"
              @click="goto('/message')"
              @mouseover="mouseover3"
              @mouseleave="mouseleave3"
            >
              <!-- <img
                class="ava"
                :src="avatar"
                alt=""
                style="border-radius: 50%"
              /> -->
              <div class="username">{{ username }}</div>
              <div class="logout" v-if="username">
                <div class="botton" @click="logout">退出</div>
              </div>
            </div>
            <div class="code">
              <div class="wximg wximg1">
                <img class="ewm" src="../assets/ewm.png" alt="" />
              </div>
              <div class="wximg wximg2">
                <img class="ewm" src="../assets/ewm.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "../api/index";
export default {
  name: "headmenu",
  data() {
    return {
      isshow: false,
      yc: false,
      yc2: false,
      yc3: false,
      username: localStorage.getItem("username"),
      avatar: localStorage.getItem("avatar"),
    };
  },
  methods: {
    goapply() {
      if (localStorage.getItem("token")) {
        if (localStorage.getItem("ismember") == 1) {
          this.$message.success("您已入会");
        } else {
          this.$router.push("/apply");
        }
      } else {
        this.$message.error("请先登录");
        this.$router.push({ path: "/login", query: { islogin: 0 } });
      }
    },
	gomessage() {
      if (localStorage.getItem("token")) {
        this.$router.push("/message");
      } else {
        this.$message.error("请先登录");
        this.$router.push({ path: "/login" });
      }
    },
    logout() {
      let that = this;
      that.Loading(".user");
      logout().then((res) => {
        that.isloading.close();
        if (res.data.code == 1) {
          that.$message.success(res.data.msg);
          that.goto("/login");
        } else {
          that.$message.error(res.data.msg);
        }
      });
    },
    goto(url) {
      this.$router.push(url);
    },
    mouseover() {
      this.yc = true;
    },
    mouseleave() {
      this.yc = false;
    },
    mouseover2() {
      this.yc2 = true;
    },
    mouseleave2() {
      this.yc2 = false;
    },
    mouseover3() {
      this.yc3 = true;
    },
    mouseleave3() {
      this.yc3 = false;
    },
  },
};
</script>

<style scoped lang="scss">
.head_wap {
  background: #fff;
  height: 60px;
  line-height: 60px;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  width: 100%;
  display: none;
  z-index: 999;
  .head {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    .logo {
      height: 60px;
      width: 200px;
      align-items: center;
      display: grid;

      img {
        width: 200px;
      }
    }

    .menu {
      z-index: 999;
      align-items: center;
      display: grid;

      .imgs {
        width: 20px;
        height: auto;
      }

      .wap_bg {
        position: fixed;
        top: 60px;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 99;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        margin-top: 60px;
      }

      .list_cont {
        height: 100vh;
        width: 250px;
        background: #fff;
        z-index: 999;
        position: fixed;
        right: 0px;
        top: 60px;

        .center {
          ul {
            list-style: none;
            padding-left: 0px;
            margin: 10px 0;

            li {
              font-size: 14px;
              cursor: pointer;
              height: 40px;
              line-height: 40px;
              padding-left: 20px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }
          }
        }

        .right {
          // border: 1px solid red;
          width: 100%;
          align-items: center;

          .ava {
            margin-left: 15px;
            height: 47px;
            width: 47px;
          }

          .code {
            display: flex;
            justify-content: center;
            margin-top: 10px;

            .wximg1 {
              .ewm {
                margin: 0 5px;
              }
            }

            .sharewx {
              width: 33px;
              height: 33px;
            }
          }

          .user {
            cursor: pointer;
            display: flex;
            align-items: center;
			flex-wrap: wrap;
            // position: relative;

            // img {
            //   height: 50px;
            //   width: 50px;
            //   margin-right: 10px;
            // }
			.username{

			}
            .logout {
				margin-left: 10px;
              //position: absolute;
              background: #fff;
              height: 30px;
              width: 60px;
            //   top: 20%;
            //   right: 1%;
            //   border-radius: 6px;
            //   border: 1px solid #236ce4;

              .botton {
                //position: absolute;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);
                height: 20px;
                width: 40px;
                border-radius: 5px;
                background: #409eff;
                color: #fff;
                font-size: 14px;
                text-align: center;
                padding: 2px 4px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .head_wap {
    display: block;
  }
}
</style>