<template>
  <div class="box">
    <div class="sidebar">
      <sidebar></sidebar>
    </div>
    <div class="declare">
      <Header></Header>
      <div class="declare_cont">
        <div class="all" v-if="listinfo.data.length > 0">
          <div class="head">
            <div class="name">项目名称</div>
            <div class="number">项目编号</div>
            <div class="time">申报时间</div>
            <div class="time2">发布时间</div>
            <div class="handle">操作</div>
          </div>
          <div class="list_cont">
            <div
              class="list"
              v-for="(item, index) in listinfo.data"
              :key="index"
            >
              <div class="name">{{ item.title }}</div>
              <div class="number">{{ item.order }}</div>
              <div class="time">{{ item.apply_time_text }}</div>
              <div class="time2">{{ item.create_time_text }}</div>
              <div class="handle" @click="goto(`/statistics/${item.id}`)">
                申报
              </div>
            </div>
          </div>
        </div>
        <div v-else style="text-align: center; padding: 100px">没有更多了</div>
        <div class="paging">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page="listinfo.current_page"
            @current-change="currentchange"
            :total="listinfo.total"
          >
          </el-pagination>
        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import { project } from "../api/index";
import Header from "../components/header2.vue";
import Footer from "../components/footer.vue";
import sidebar from "../components/sidebar.vue";

export default {
  data() {
    return {
      listinfo: {},
      page: 1,
      limit: 10,
    };
  },
  components: {
    Header,
    Footer,
    sidebar,
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.project();
    } else {
		this.$message.error('请登陆后操作');
      this.$router.push("/login");
    }
  },
  methods: {
    goto(url) {
      this.$router.push(url);
    },
    project() {
      this.Loading(".list_cont");
      project({
        page: this.page,
        limit: this.limit,
      }).then((res) => {
        this.isloading.close();
        if (res.data.code == 1) {
          this.listinfo = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    currentchange(page) {
      // console.log(page)
      this.page = page;
      this.limit = 10;
      this.project();
    },
  },
};
</script>

<style scoped lang="scss">
body {
  padding: 0;
  margin: 0;
}

.box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sidebar {
  width: 18%;
}

.declare {
  width: 82%;
  min-height: 100%;
  background: linear-gradient(
    180deg,
    rgba(171, 198, 246, 0.27) 0%,
    rgba(216, 224, 232, 0.1) 100%
  );
}

.declare_cont {
  border-radius: 5px;
  background: #fff;
  margin: 35px 72px 91px 82px;
  position: relative;
  padding-bottom: 48px;
}

.declare_cont .head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #1890ff;
  padding: 0 60px;
  height: 97px;
  line-height: 97px;
  color: #fff;
}

.declare_cont .head .name {
  width: 30%;
  text-align: center;
}

.declare_cont .head .number {
  width: 10%;
  text-align: center;
}

.declare_cont .head .time {
  width: 15%;
  text-align: center;
}

.declare_cont .head .time2 {
  width: 15%;
  text-align: center;
}

.declare_cont .head .handle {
  width: 10%;
  text-align: center;
}

.declare_cont .list_cont .list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 60px;
  height: 55px;
  line-height: 55px;
  border-bottom: 1px solid #f4f4f4;
  color: #000;
  text-align: center;
  font-size: 18px;
}

.declare_cont .list_cont .list .name {
  width: 30%;
  text-align: center;
}

.declare_cont .list_cont .list .number {
  width: 10%;
  text-align: center;
}

.declare_cont .list_cont .list .time {
  width: 15%;
  text-align: center;
}

.declare_cont .list_cont .list .time2 {
  width: 15%;
  text-align: center;
}

.declare_cont .list_cont .list .handle {
  width: 10%;
  text-align: center;
  color: #1890ff;
  cursor: pointer;
}

.declare_cont .paging {
  display: flex;
  justify-content: center;
  margin-top: 83px;

  /deep/.el-pagination.is-background .el-pager li {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }

  /deep/.el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }

  /deep/.el-pagination.is-background .btn-prev:disabled {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }

  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    border: 1px solid #1890ff;
    background-color: #fff;
    color: #1890ff;
    height: 30px;
  }

  /deep/.el-pagination.is-background .btn-prev {
    background-color: #fff;
    color: #898989;
    border: 1px solid #d9d9d9;
    font-family: PingFang SC;
    font-weight: 400;
    height: 30px;
  }
}

@media only screen and (max-width: 1800px) {
  .declare_cont .list_cont .list {
    font-size: 16px;
  }

  .declare_cont .head {
    padding: 0 20px;
    height: 70px;
    line-height: 70px;
    color: #fff;
  }

  .declare_cont .list_cont .list {
    margin: 0 20px;
    font-size: 16px;
  }

  .declare_cont .list_cont .list .name {
    font-size: 14px;
  }

  .declare_cont .list_cont .list .time {
    width: 20%;
    text-align: center;
  }

  .declare_cont .list_cont .list .number {
    width: 15%;
    text-align: center;
  }

  .declare_cont .list_cont .list .time2 {
    width: 20%;
    text-align: center;
  }

  .declare_cont .head .number {
    width: 15%;
    text-align: center;
  }

  .declare_cont .head .time {
    width: 20%;
    text-align: center;
  }

  .declare_cont .head .time2 {
    width: 20%;
    text-align: center;
  }
}

@media only screen and (max-width: 1366px) {
  .declare_cont {
    background: #fff;
    margin: 30px 30px 0;
    padding: 30px;
  }

  .declare_cont .search .input .text {
    font-size: 16px;
    width: 65px;
  }

  .declare_cont .search .input .el-input__inner {
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    background: #fff;
    padding-left: 20px;
    color: #aaa;
    font-size: 16px;
  }

  .declare_cont .paging {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1100px) {
  .declare_cont {
    background: #fff;
    margin: 30px 30px 0;
    padding: 30px;
  }

  .declare_cont .search .input .text {
    font-size: 16px;
    width: 65px;
  }

  .declare_cont .search .input .el-input__inner {
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    background: #fff;
    padding-left: 20px;
    color: #aaa;
    font-size: 16px;
  }

  .declare_cont .paging {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .box {
    // width: 100vw;
    // height: 100vh;
    .sidebar {
      width: 0;
    }

    .declare {
      width: 100%;
      height: 100vh;

      .declare_cont {
        // width: 500px;
        // overflow: scroll;
        // overflow-x: auto;
        /deep/.all {
          overflow: scroll;
          overflow-x: auto;

          .head {
            width: 600px;
          }

          .list_cont {
            width: 640px;
          }
        }
      }
    }
  }

  .declare_cont .head {
    padding: 0 20px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    font-size: 14px;
  }

  .declare_cont {
    background: #fff;
    margin: 10px 10px 0;
    padding: 10px;
  }

  .declare_cont .list_cont .list {
    margin: 0 20px;
    font-size: 14px;
  }
}
</style>