<template>
  <div class="header">
    <div class="logo" @click="goto('/index')">
      <img src="../assets/logo.png" alt="" />
    </div>
    <div class="info">
      <div class="list" @mouseover="mouseover" @mouseleave="mouseleave">
        <img class="sharewx" src="../assets/wx.png" alt="" />
        <div class="wximg" v-show="yc">
          <img class="ewmborder" src="../assets/ewmborder.png" alt="" />
          <img class="ewm" src="../assets/weixinewm.jpg" alt="" />
        </div>
      </div>
      <div class="list" @mouseover="mouseover2" @mouseleave="mouseleave2">
        <img class="sharewx" src="../assets/weibo.png" alt="" />
        <div class="wximg" v-show="yc2">
          <img class="ewmborder" src="../assets/ewmborder.png" alt="" />
          <img class="ewm" src="../assets/weiboewm.png" alt="" />
        </div>
      </div>
      <div
        class="login"
        @mouseover="mouseover3"
        @mouseleave="mouseleave3"
        @click.stop="gologin"
      >
        <!-- <img
          class="ava"
          :src="avatar"
          alt=""
          style="border-radius: 50%"
          v-if="avatar"
        />
        <img
          class="ava"
          src="../assets/useravatop.png"
          alt=""
          style="border-radius: 50%"
          v-else
        /> -->
        <span style="width: 50%; font-size: 16px">{{
          username || "未登录"
        }}</span>
        <div class="logout" v-show="yc3 && username">
          <div class="botton" @click.stop="logout">退出</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "../api/index";
export default {
  name: "Header",
  props: {},
  data() {
    return {
      yc: false,
      yc2: false,
      yc3: false,
      username: localStorage.getItem("username"),
      avatar: localStorage.getItem("avatar"),
    };
  },
  methods: {
    gologin() {
      if (!localStorage.getItem("token")) {
        this.$message.error("请先登录");
        this.$router.push({ path: "/login" });
      } else {
      }
    },
    logout() {
      let that = this;
      that.Loading(".user");
      logout().then((res) => {
        that.isloading.close();
        if (res.data.code == 1) {
          window.localStorage.clear();
          that.$message.success(res.data.msg);
          that.goto("/login");
        } else {
          // that.$message.error(res.data.msg);
        }
      });
    },
    goto(url) {
      this.$router.push(url);
    },
    mouseover() {
      this.yc = true;
    },
    mouseleave() {
      this.yc = false;
    },
    mouseover2() {
      this.yc2 = true;
    },
    mouseleave2() {
      this.yc2 = false;
    },
    mouseover3() {
      this.yc3 = true;
    },
    mouseleave3() {
      this.yc3 = false;
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 118px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(58, 58, 58, 0.04);

  .logo {
    width: 60%;
    cursor: pointer;
    .img {
      width: 100%;
    }
  }

  .info {
    width: 20%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .list {
      width: 5%;
      z-index: 999;
      position: relative;

      .wximg {
        position: absolute;
        left: -50px;

        .ewm {
          position: absolute;
          top: 21px;
          left: 11px;
          width: 110px;
          height: 110px;
        }
      }

      .sharewx {
        width: 33px;
        height: 33px;
      }
    }

    .login {
      width: 60%;
      color: #3d3d3d;
      position: relative;
      font-size: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        margin-right: 10px;
      }

      .logout {
        position: absolute;
        background: #fff;
        height: 50px;
        width: 80px;
        top: 100%;
        // right: -10%;
        border-radius: 10px;
        border: 1px solid #236ce4;

        .botton {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 20px;
          width: 40px;
          border-radius: 5px;
          background: #409eff;
          color: #fff;
          font-size: 14px;
          text-align: center;
          padding: 2px 4px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .header .logo {
    width: 400px;
    cursor: pointer;
  }

  .header .logo img {
    width: 100%;
  }

  .header .info {
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

@media only screen and (max-width: 1100px) {
  .header .logo {
    width: 300px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    width: 100vw;
    height: 60px;

    .logo {
      cursor: pointer;
      height: 50px;
      line-height: 60px;
      padding-left: 10px;

      img {
        width: 200px;
        height: auto;
      }
    }

    .info {
      .login {
        font-size: 20px;
        cursor: pointer;

        img {
          width: calc(100vw * 150 / 1920);
          height: calc(100vw * 150 / 1920);
        }

        span {
          display: none;
        }
      }

      .list {
        opacity: 0;
      }
    }
  }

  .header .info .login .logout {
    position: absolute;
    background: #fff;
    height: 30px;
    width: 60px;
    top: 100%;
    right: 0%;
    border-radius: 5px;
    border: 1px solid #236ce4;
    z-index: 999;
  }
}
</style>