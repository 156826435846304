<template>
	<div>
		<Header></Header>
		<headmenu></headmenu>
		<div class="success">
			<img src="../assets/succes.png" alt="" />
			<h3>提交成功</h3>
			<span>会申请提交成功，请等待审核。我们将在5个工作日内通知审核结果。</span>
			<div class="alert">
				<img src="../assets/alert.png" alt="" />
				<span>注:成功信息推送至联系人邮箱</span>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "../components/header.vue";
	import Footer from "../components/footer.vue";
	import headmenu from "../components/headmenu.vue";
	export default {
		components: {
			Header,
			Footer,
			headmenu
		},
	};
</script>

<style lang="scss" scoped>
	.success {
		margin: 168px auto 110px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 53vh;

		h3 {
			font-size: 26px;
			color: #1890ff;
			margin-bottom: 10px;
		}

		span {
			font-size: 18px;
			color: #434343;
		}

		.alert {
			display: flex;
			align-items: center;
			margin-top: 30px;

			img {
				padding-right: 5px;
				width: 13px;
				height: 13px;
			}

			span {
				color: #969696;
				font-size: 16px;
			}
		}
	}

	@media only screen and (max-width: 1366px) {
		.success h3 {
		    font-size: 24px;
		    color: #1890ff;
		    margin-bottom: 10px;
		}
		.success .alert {
		    display: flex;
		    align-items: center;
		    margin-top: 20px;
		}
		.success {
		    margin: 110px auto 110px;
		    display: flex;
		    flex-direction: column;
		    justify-content: center;
		    align-items: center;
		    height: 53vh;
		}
		.kefu[data-v-27e96f68] {
		    cursor: pointer;
		    position: absolute;
		    right: 0;
		    top: 35%;
		}
	}
	@media only screen and (max-width: 1100px){
		.success h3 {
		    font-size: 18px;
		}
		.success span {
		    font-size: 14px;
		}
		.success .alert {
		    display: flex;
		    align-items: center;
		    margin-top: 10px;
		}
		.success .alert span {
		    color: #969696;
		    font-size: 14px;
		}
	}
	@media only screen and (max-width: 768px){
		.success{
			padding: 0 10px;
			margin-top: 70px;
		}
	}
</style>