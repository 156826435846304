<template>
	<div>
		<Header></Header>
		<headmenu></headmenu>
		<div class="guide">
			<div class="title">
				<h1>入会指南</h1>
				<div class="line"></div>
			</div>
			<div class="text">
				<span>
					<div v-html="guide"></div>
				</span>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import {
		applyGuide
	} from '../api/index'
	import Header from "../components/header.vue";
	import Footer from "../components/footer.vue";
	import headmenu from "../components/headmenu.vue";
	export default {
		data() {
			return {
				guide: ''
			}
		},
		components: {
			Header,
			Footer,
			headmenu
		},
		mounted() {
			this.applyGuide()
		},
		methods: {
			applyGuide() {
				applyGuide().then(res => {
					console.log(res)
					this.guide = res.data.data
				})
			}
		}
	};
</script>

<style lang="scss">
	.guide {
		width: 70%;
		border-radius: 5px;
		margin: 50px auto 60px;
		background-color: #fff;

		.title {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;

			h1 {
				font-size: 32px;
			}

			.line {
				margin-top: -5px;
				width: 50px;
				text-align: center;
				border-bottom: 4px solid #1890ff;
			}
		}

		.text {
			padding: 30px 70px;
			// font-size: 18px;

			span {
				// font-size: 18px;
				// line-height: 34px;
			}
		}
	}

	@media only screen and (max-width: 1366px) {
		.guide .title h1 {
			font-size: 24px;
		}

		.guide .text span {
			// font-size: 16px;
			// line-height: 30px;
		}

		.guide .text {
			// padding: 20px 20px;
			// font-size: 16px;
		}

		.guide {
			width: 1000px;
			margin: 20px auto 30px;
		}
	}

	@media only screen and (max-width: 1100px) {
		.guide {
			width: 750px;
			margin: 20px auto 30px;
		}

		.guide .text[data-v-41acea1e] {
			// padding: 10px 10px;
			// font-size: 14px;
		}

		.guide .text span[data-v-41acea1e] {
			// font-size: 14px;
			// line-height: 30px;
			// padding: 0px;
		}
	}

	@media only screen and (max-width: 768px) {
		.guide .title h1 {
			font-size: 18px;
		}

		.guide {
			width: 98%;
			margin: 70px auto 30px;
		}
	}
</style>