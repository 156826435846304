import request from "@/utils/request.js";

//-------全局--------
export function userinformation(data) {
  return request({
    url: `/api/member/userinformation`,
    method: "post",
    data: data
  });
}
//-------首页--------
//账号登录
export function login(data) {
  return request({
    url: `/api/user/login`,
    method: "post",
    data: data
  });
}
//手机登录
export function mobilelogin(data) {
  return request({
    url: `/api/user/mobilelogin`,
    method: "post",
    data: data
  });
}
//账号注册
export function register(data) {
  return request({
    url: `/api/user/register`,
    method: "post",
    data: data
  });
}

//发送验证码
export function sendcode(data) {
  return request({
    url: `/addons/cms/api.sms/send`,
    method: "post",
    data: data
  });
}

//账号退出
export function logout(data) {
  return request({
    url: `/api/user/logout`,
    method: "post",
    data: data
  });
}
//获取首页信息
export function getindexinfo() {
  return request({
    url: `/api/index/index`,
    method: "post"
  });
}
//省份
export function areaAll(data) {
  return request({
    url: `/api/index/areaAll`,
    method: "post",
    data: data
  });
}
//获取所有公告
export function getnoticeList(data) {
  return request({
    url: `/api/index/noticeList`,
    method: "post",
    data: data
  });
}
//获取公告详情
export function getnoticeDetails(data) {
  return request({
    url: `/api/index/noticeDetails`,
    method: "post",
    data: data
  });
}
//在线留言
export function contactUs(data) {
  return request({
    url: `/api/index/contactUs`,
    method: "post",
    data: data
  });
}

//-------会员相关--------
//会员服务列表
export function serviceList(data) {
  return request({
    url: `/api/member/service`,
    method: "post",
    data: data
  });
}
//会员服务详情
export function serviceDetails(data) {
  return request({
    url: `/api/member/serviceDetails`,
    method: "post",
    data: data
  });
}
//入会申请
export function memberApply(data) {
  return request({
    url: `/api/member/memberApply`,
    method: "post",
    data: data
  });
}
//入会申请——归口部门/专业领域
export function getOtherSelect(data) {
  return request({
    url: `/api/member/getOtherSelect`,
    method: "post",
    data: data
  });
}
//入会申请——生成文档
export function exportWord(data) {
  return request({
    url: `/api/member/exportWord`,
    method: "post",
    data: data
  });
}
//入会指南
export function applyGuide(data) {
  return request({
    url: `/api/member/guide`,
    method: "post",
    data: data
  });
}

//会员名录
export function memberDirectory(data) {
  return request({
    url: `/api/member/memberDirectory`,
    method: "post",
    data: data
  });
}

//获取会员等级
export function getLevelByDepart(data) {
  return request({
    url: `/api/member/getLevelByDepart`,
    method: "post",
    data: data
  });
}
//退会申请
export function outApply(data) {
  return request({
    url: `/api/member/outApply`,
    method: "post",
    data: data
  });
}

//-------用户中心--------
//消息中心
export function message(data) {
  return request({
    url: `/api/user/message`,
    method: "post",
    data: data
  });
}
//消息详情
export function messageDetails(data) {
  return request({
    url: `/api/user/messageDetails`,
    method: "post",
    data: data
  });
}
//修改信息
export function getEditMember(data) {
  return request({
    url: `/api/user/getEditMember`,
    method: "post",
    data: data
  });
}
//修改密码
export function setPwd(data) {
  return request({
    url: `/api/user/setPwd`,
    method: "post",
    data: data
  });
}
//证书列表
export function certificate(data) {
  return request({
    url: `/api/user/certificate`,
    method: "post",
    data: data
  });
}
//缴费列表
export function getPayList(data) {
  return request({
    url: `/api/pay/getPayList`,
    method: "post",
    data: data
  });
}
//缴费
export function pay(data) {
  return request({
    url: `/api/pay/pay`,
    method: "post",
    data: data
  });
}
//获取缴费信息
export function getData(data) {
  return request({
    url: `/api/pay/getData`,
    method: "post",
    data: data
  });
}

//项目申报列表
export function project(data) {
  return request({
    url: `/api/user/project`,
    method: "post",
    data: data
  });
}
//项目申报详情
export function projectDetails(data) {
  return request({
    url: `/api/user/projectDetails`,
    method: "post",
    data: data
  });
}
//项目申报
export function projectApply(data) {
  return request({
    url: `/api/user/projectApply`,
    method: "post",
    data: data
  });
}