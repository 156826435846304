<template>
	<div class="box">
		<div class="sidebar">
			<sidebar></sidebar>
		</div>
		<div class="cate">
			<Header></Header>
			<div class="cate_cont">
				<div class="search">
					<div class="input">
						<div class="text">年度：</div>
						<el-input placeholder="请输入" v-model="year" clearable @change="changeYear"></el-input>
					</div>
					<div class="input">
						<div class="text">名称：</div>
						<el-input placeholder="请输入" v-model="title" clearable @change="changeTitle"></el-input>
					</div>
				</div>
				<div class="tabel">
					<div class="head">
						<div class="year">年度</div>
						<div class="name">证书名称</div>
						<div class="time">发放时间</div>
						<div class="handle">操作</div>
					</div>
					<div class="tabel_cont" v-if="listinfo.data.length > 0">
						<div class="list" v-for="(item, index) in listinfo.data" :key="index">
							<div class="year">{{ item.year }}</div>
							<div class="name">{{ item.title }}</div>
							<div class="time">{{ item.create_time_text }}</div>
							<div class="handle" @click="download(item.cdn_file)">
								证书下载
							</div>
						</div>
					</div>
					<div v-else style="text-align: center; padding: 100px">
						没有更多了
					</div>
					<div class="paging">
						<el-pagination background layout="prev, pager, next" :current-page="listinfo.current_page"
							@current-change="currentchange" :total="listinfo.total">
						</el-pagination>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
	import {
		certificate
	} from "../api/index";
	import Header from "../components/header2.vue";
	import Footer from "../components/footer.vue";
	import sidebar from "../components/sidebar.vue";
	export default {
		components: {
			Header,
			Footer,
			sidebar,
		},
		data() {
			return {
				listinfo: {},
				page: 1,
				limit: 8,
				title: "",
				year: "",
			};
		},
		mounted() {
			this.certificate();
		},filters: {
			dateFormat(key) {
				key = key * 1000; //注意：key应为格式为13位unix时间戳，如果是10位的unix时间戳，需要乘以1000
				const dt = new Date(key);
				const y = dt.getFullYear();
				// const m = (dt.getMonth() + 1 + "").padStart(2, "0");
				// const d = (dt.getDate() + "").padStart(2, "0");
				//时间格式年月日、时分秒
				return `${y}`;
			},
		},
		methods: {

			changeYear(e) {
				// console.log('change',e)
				this.page = 1;
				this.year = e;
				this.certificate();
			},
			changeTitle(e) {
				// console.log('change',e)
				this.page = 1;
				this.title = e;
				this.certificate();
			},
			certificate() {
				this.Loading(".tabel_cont");
				certificate({
					page: this.page,
					limit: this.limit,
					title: this.title,
					year: this.year,
				}).then((res) => {
					this.isloading.close();
					if (res.data.code == 1) {
						this.listinfo = res.data.data;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			},
			currentchange(page) {
				// console.log(page)
				this.page = page;
				this.limit = 8;
				this.certificate();
			},
			download(file) {
				const link = document.createElement("a");
				link.href = file;
				link.download = "会员证书.docx";
				link.target = "_blank";
				document.body.appendChild(link);
				link.click();
				// window.open(file);
			},
		},
	};
</script>

<style scoped lang="scss">
	body {
		padding: 0;
		margin: 0;
	}

	.box {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.sidebar {
		width: 18%;
	}

	.cate {
		width: 82%;
		background: linear-gradient(180deg,
				rgba(171, 198, 246, 0.27) 0%,
				rgba(216, 224, 232, 0.1) 100%);
	}

	.cate_cont {
		height: auto;
		border-radius: 5px;
		background: #fff;
		margin: 35px 72px 91px 82px;
		padding: 42px 61px 67px 59px;
		position: relative;
	}

	.cate_cont .search {
		display: flex;
	}

	.cate_cont .search .input {
		display: flex;
		width: 40%;
		margin-right: 5%;
		align-items: center;
	}

	.cate_cont .search .input .text {
		color: #000;
		text-align: center;
		font-size: 20px;
		font-weight: 500;
		width: 100px;
	}

	.cate_cont .search .input .el-input__inner {
		height: 60px;
		line-height: 60px;
		border-radius: 5px;
		background: #fff;
		padding-left: 20px;
		color: #aaa;
		font-size: 16px;
	}

	.cate_cont .tabel {
		margin-top: 50px;
		box-shadow: 1px 1px 4px #f5f5f5;
		padding-bottom: 50px;
	}

	.cate_cont .tabel .head {
		padding: 0 70px 0 12px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		height: 55px;
		line-height: 55px;
		background: #f6f6f6;
		color: #000;
		text-align: center;
		font-size: 18px;
	}

	.cate_cont .tabel .head .year {
		width: 15%;
		text-align: center;
	}

	.cate_cont .tabel .head .name {
		width: 25%;
		text-align: center;
	}

	.cate_cont .tabel .head .time {
		width: 25%;
		text-align: center;
	}

	.cate_cont .tabel .head .handle {
		width: 15%;
		text-align: center;
	}

	.cate_cont .tabel .tabel_cont .list {
		margin: 0 70px 0 12px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		height: 55px;
		line-height: 55px;
		background: #fff;
		color: #000;
		text-align: center;
		font-size: 18px;
		border-bottom: 1px solid #f4f4f4;
	}

	.cate_cont .tabel .tabel_cont {
		margin-bottom: 120px;
	}

	.cate_cont .tabel .tabel_cont .year {
		width: 15%;
		text-align: center;
	}

	.cate_cont .tabel .tabel_cont .name {
		width: 25%;
		text-align: center;
	}

	.cate_cont .tabel .tabel_cont .time {
		width: 25%;
		text-align: center;
	}

	.cate_cont .tabel .tabel_cont .handle {
		width: 15%;
		text-align: center;
		color: #1890ff;
		cursor: pointer;
	}

	.cate_cont .tabel .paging {
		display: flex;
		justify-content: center;
		margin-top: 83px;

		/deep/.el-pagination.is-background .el-pager li {
			background-color: #fff;
			color: #898989;
			border: 1px solid #d9d9d9;
			font-family: PingFang SC;
			font-weight: 400;
			height: 30px;
		}

		/deep/.el-pagination.is-background .btn-next,
		.el-pagination.is-background .btn-prev,
		.el-pagination.is-background .el-pager li {
			background-color: #fff;
			color: #898989;
			border: 1px solid #d9d9d9;
			font-family: PingFang SC;
			font-weight: 400;
			height: 30px;
		}

		/deep/.el-pagination.is-background .btn-prev:disabled {
			background-color: #fff;
			color: #898989;
			border: 1px solid #d9d9d9;
			font-family: PingFang SC;
			font-weight: 400;
			height: 30px;
		}

		/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
			border: 1px solid #1890ff;
			background-color: #fff;
			color: #1890ff;
			height: 30px;
		}

		/deep/.el-pagination.is-background .btn-prev {
			background-color: #fff;
			color: #898989;
			border: 1px solid #d9d9d9;
			font-family: PingFang SC;
			font-weight: 400;
			height: 30px;
		}
	}

	@media only screen and (max-width: 1800px) {
		.cate_cont .tabel .tabel_cont .handle .text {
			margin: 0 10px;
			font-size: 16px;
		}

		.cate_cont .tabel .tabel_cont .time {
			font-size: 16px;
		}

		.cate_cont .tabel .tabel_cont .name {
			font-size: 16px;
		}

		.cate_cont .tabel .tabel_cont .year {
			font-size: 16px;
		}

		.cate_cont .tabel .head .year {
			font-size: 16px;
		}

		.cate_cont .tabel .head .name {
			font-size: 16px;
		}

		.cate_cont .tabel .head .time {
			font-size: 16px;
		}

		.cate_cont .tabel .head .handle {
			font-size: 16px;
		}
	}

	@media only screen and (max-width: 1600px) {
		.cate_cont {
			height: auto;
		}

		.cate_cont .tabel .tabel_cont {
			margin-bottom: 0px;
		}

		.cate_cont .tabel .paging {
			display: flex;
			justify-content: center;
			margin-top: 50px;
		}
	}

	@media only screen and (max-width: 1500px) {
		.cate_cont {
			height: auto;
			border-radius: 5px;
			background: #fff;
			margin: 30px 30px 0;
			padding: 30px;
		}

		.cate_cont .search .input .text {
			font-size: 16px;
			width: 65px;
		}

		.cate_cont .search .input .el-input__inner {
			height: 50px;
			line-height: 50px;
			border-radius: 5px;
			background: #fff;
			padding-left: 20px;
			color: #aaa;
			font-size: 16px;
		}

		.cate_cont .tabel {
			margin-top: 20px;
			box-shadow: 1px 1px 4px #f5f5f5;
			padding-bottom: 20px;
		}

		.cate_cont .tabel .paging {
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}
	}

	@media only screen and (max-width: 768px) {
		.box {
			.sidebar {
				width: 0;
			}

			.cate {
				width: 100%;
				height: 100vh;

				.cate_cont {
					overflow: scroll;
					overflow-x: auto;

					.submit {
						width: auto;
					}

					.search {
						width: 800px;
					}

					.tabel {
						width: 800px;
					}
				}
			}
		}

		.cate_cont[data-v-53de6743] {
			height: auto;
			border-radius: 5px;
			background: #fff;
			margin: 10px 10px 0;
			padding: 10px;
		}
	}
</style>