<template>
  <div class="messages">
    <el-container>
      <el-aside width="18%"><sidebar></sidebar></el-aside>
      <el-container>
        <!-- <el-header><Header></Header></el-header> -->
        <Header></Header>
        <el-main>
          <Form type="0"></Form>
        </el-main>
        <el-footer><Footer></Footer></el-footer>
      </el-container>
    </el-container>
  </div>
</template>
  
  <script>
import { userinformation } from "../api/index";
import Form from "../components/form.vue";
import Header from "../components/header2.vue";
import Footer from "../components/footer.vue";
import sidebar from "../components/sidebar.vue";
export default {
  data() {
    return {};
  },
  components: {
    Form,
    Header,
    Footer,
    sidebar,
  },
  mounted() {
    userinformation().then((res) => {
      if(res.data.msg.is_member==0){
        this.$message.error("您未入会，不可更改信息");
      }
      localStorage.setItem("ismember", res.data.msg.is_member);
    });
    // if(localStorage.getItem('ismember')==0){
    //   this.$message.error("您未入会，不可更改信息");
    // }
  },
};
</script>
  
  <style lang="scss">
body {
  padding: 0;
  margin: 0;
}
.el-header {
  margin: 0;
  padding: 0;
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  height: 118px;
  line-height: 118px;
}
.el-footer {
  margin: 0;
  padding: 0;
  height: 50px;
}
.el-aside {
  background-color: #001529;
  color: #333;
  text-align: center;
  min-height: 80vh;
}

.el-main {
  background: linear-gradient(to bottom, rgba(171, 198, 246, 0.27), #ffffff)
    no-repeat;
  background-size: 100% 100%;
  color: #333;
  text-align: left;
  min-height: 80vh;
  margin-top: 10px;

  .form {
    width: 90%;
    margin: 0 auto;
  }
  .form .el-button--primary {
    margin-left: 25vw;
  }
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
@media only screen and (max-width: 768px) {
  .el-aside {
    display: none;
  }
}
</style>