<template>
  <div id="app">
    <!-- 内容 -->
    <router-view></router-view>
    <Menu></Menu>
    <chat></chat>
  </div>
</template>

<script>
import chat from "./components/chat.vue";
import Menu from "./components/menu.vue";
import { userinformation } from "./api/index";
export default {
  name: "",
  components: {
    chat,
    Menu
  },
  watch: {
    $route(to, from) {
      // 监听路由变化, 实现类似 小程序的 onShow 事件
      if (to.path === "/apply" || to.path === "/editapply") {
        userinformation().then((res) => {
          // console.log("res-----------", res.data);
          localStorage.setItem("ismember", res.data.msg.is_member);
        });
      }
    },
  },
  mounted() {
    // if (!localStorage.getItem("token")) {
    //   this.$router.push("/login");
    // } else {
    // }
  },
  methods: {
    // userinfo() {
    //   userinformation().then((res) => {
    //     // console.log("res-----------", res.data);
    //     localStorage.setItem('ismember',res.data.msg.is_member)
    //   });
    // },
  },
};
</script>

<style lang="scss">
body {
  background: linear-gradient(to bottom, #abc6f6, #ffffff) no-repeat;
  background-size: 100% 100%;
}
</style>
